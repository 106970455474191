import React, { useState, useEffect, useRef } from 'react';
import '../css/FundPlan.css';

function FundPlan({ selectedOption, existingFund: initialExistingFund, onBudgetDataChange, onSave }) {
  // Declare hooks at the top level
  const [existingFund, setExistingFund] = useState(() => parseFloat(sessionStorage.getItem('existingFund')) || initialExistingFund || 0);
  const [monthlyContributionForEmergencyFund, setMonthlyContributionForEmergencyFund] = useState(0);
  const [timeToReachGoal, setTimeToReachGoal] = useState(null);
  const [calculatedMonthlyContribution, setCalculatedMonthlyContribution] = useState(null);
  const [monthsToBuild, setMonthsToBuild] = useState(() => sessionStorage.getItem('monthsToBuild') || '');
  const [monthlyContributionByAmount, setMonthlyContributionByAmount] = useState(0);
  const hasSetDefaultContribution = useRef(false);
  const [isBuildByAmount, setIsBuildByAmount] = useState(false);
  const [showAddToBudgetButton, setShowAddToBudgetButton] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Calculate and set default monthly contribution only once
  useEffect(() => {
    if (selectedOption && !hasSetDefaultContribution.current) {
      setMonthlyContributionForEmergencyFund(0);
      hasSetDefaultContribution.current = true;
    }
  }, [selectedOption]);

  // Load stored values from session storage when the component mounts
  useEffect(() => {
    const storedExistingFund = sessionStorage.getItem('existingFund');
    const storedMonthsValue = sessionStorage.getItem('monthsToBuild');

    if (storedExistingFund) {
      setExistingFund(parseFloat(storedExistingFund));
    }
    if (storedMonthsValue) {
      setMonthsToBuild(storedMonthsValue);
    }
  }, []);

  const handleExistingFundChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setExistingFund(value);
    sessionStorage.setItem('existingFund', value); // Store in session storage
  };

  const handleContributionChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setMonthlyContributionByAmount(value);
    sessionStorage.setItem('monthlyContributionByAmount', value); // Store in session storage
  };

  const handleCalculateTime = () => {
    if (monthlyContributionByAmount > 0) {
      const remainingFundNeeded = selectedOption.value - existingFund;
      const monthsNeeded = remainingFundNeeded / monthlyContributionByAmount;
      setTimeToReachGoal(monthsNeeded.toFixed(1));
      sessionStorage.setItem('calculatedTime', monthsNeeded.toFixed(1)); // Store in session storage
      setShowAddToBudgetButton(true);
    }
  };

  const handleCalculateMonthlyContributionByTime = () => {
    const totalAmountNeeded = selectedOption.value - existingFund;
    const months = parseInt(monthsToBuild, 10);
    
    if (months > 0) {
      const calculatedContribution = totalAmountNeeded / months;
      setCalculatedMonthlyContribution(calculatedContribution > 0 ? calculatedContribution : 0);
      sessionStorage.setItem('calculatedAmount', calculatedContribution > 0 ? calculatedContribution : 0); // Store in session storage
      setShowAddToBudgetButton(true);
    } else {
      setCalculatedMonthlyContribution(0);
    }
  };

  const handleMonthsToBuildChange = (e) => {
    const value = e.target.value;
    setMonthsToBuild(value);
    sessionStorage.setItem('monthsToBuild', value); // Store in session storage
  };

  const handleAddToBudget = () => {
    let effectiveContribution = isBuildByAmount ? monthlyContributionByAmount : calculatedMonthlyContribution;

    // Save the effective contribution to local storage
    localStorage.setItem('monthlyContributionForEmergencyFund', effectiveContribution);
    
    // Update the budget data for Emergency Fund
    const currentBudgetData = JSON.parse(localStorage.getItem('budgetData')) || {};
    
    const updatedBudgetData = {
      ...currentBudgetData,
      'Emergency Fund': effectiveContribution > 0 ? effectiveContribution : 0,
    };
    
    // Save the updated budget data back to local storage
    localStorage.setItem('budgetData', JSON.stringify(updatedBudgetData));

    // Call the onBudgetDataChange function to update the state in the parent components
    if (onBudgetDataChange) {
        onBudgetDataChange('Emergency Fund', effectiveContribution > 0 ? effectiveContribution : 0);
    }

    // Show success message
    setSuccessMessage(`Successfully added ₹${effectiveContribution.toFixed(2)} to your monthly budget!`);

    // Call onSave to enable the Next button
    if (onSave) {
        onSave();
    }
  };

  return (
    <div className="fund-plan-page">
      <h2>Build Your Emergency Fund</h2>
      <div className="selected-fund-info">
        <h3>Target Fund: {selectedOption?.title}</h3>
        <p>Target Amount: ${selectedOption?.value.toFixed(2)}</p>
      </div>
      <div className="contribution-section">
        <label htmlFor="existing-fund"><b>Existing Emergency Fund</b></label>
        <input
          type="number"
          id="existing-fund"
          value={existingFund}
          onChange={handleExistingFundChange}
          min="0"
          className="input-number"
          placeholder="Enter your existing emergency fund"
        />
      </div>
      <div className="build-emergency-fund-section">
        <p>To build your emergency fund, choose one of the following options:</p>
        <div className="build-options">
          <button 
            className={`build-option-button-time ${!isBuildByAmount ? 'active' : ''}`} 
            onClick={() => setIsBuildByAmount(false)}
          >
            Build by Time
          </button>
          <button 
            className={`build-option-button-amount ${isBuildByAmount ? 'active' : ''}`} 
            onClick={() => setIsBuildByAmount(true)}
          >
            Build by Amount
          </button>
        </div>
      </div>

      {isBuildByAmount && (
        <div className="contribution-section">
          <label htmlFor="monthly-contribution"><b>Monthly Contribution</b></label>
          <input
            type="number"
            id="monthly-contribution"
            value={monthlyContributionByAmount}
            onChange={handleContributionChange}
            min="0"
            className="input-number"
            placeholder="Enter the amount you can save each month"
          />
          <button onClick={handleCalculateTime} className="calculate-time-button">
            Calculate Time to Reach Goal
          </button>

          {timeToReachGoal && (
            <>
              <div className="time-result">
                <h3>Time to Reach Goal: {timeToReachGoal} months</h3>
              </div>
              <button onClick={handleAddToBudget} className="add-to-budget-button">
                Add to Budget
              </button>
            </>
          )}
        </div>
      )}

      {!isBuildByAmount && (
        <div className="contribution-section">
          <label htmlFor="months-to-build"><b>Number of Months to Build Fund</b></label>
          <input
            type="number"
            id="months-to-build"
            value={monthsToBuild}
            onChange={handleMonthsToBuildChange}
            min="1"
            className="input-number"
            placeholder="Enter number of months"
          />
          <button onClick={handleCalculateMonthlyContributionByTime} className="calculate-time-button">
            Calculate Monthly Contribution
          </button>

          {calculatedMonthlyContribution !== null && (
            <>
              <div className="time-result">
                <h3>Calculated Monthly Contribution: ₹{calculatedMonthlyContribution.toFixed(2)}</h3>
              </div>
              <button onClick={handleAddToBudget} className="add-to-budget-button">
                Add to Budget
              </button>
            </>
          )}
        </div>
      )}

      {successMessage && (
        <div className="success-message">
          {successMessage}
        </div>
      )}
    </div>
  );
}

export default FundPlan;
