import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import '../css/SpendAnalyzer.css';
import '../css/CommonLayout.css';
import { FaTrash } from 'react-icons/fa';

// Add these at the top of the file, before the SpendAnalyzer component
const categoryMapping = {
  Essentials: ['Rent', 'Groceries', 'Utilities', 'Transport'],
  Discretionary: ['Travel', 'Dining', 'Shopping', 'Subscriptions'],
  Debts: ['Personal Loan', 'Credit Card Payment', 'Car loan', 'Gold loan', 'Home loan'],
  'Short-term Savings': ['Short-term Savings', 'Emergency Fund'],
  'Long-term Savings': ['Long-term Savings', 'Investments', 'Pension'],
  'Emergency Fund': ['Emergency Fund'],
  Insurance: ['Term Insurance', 'Health Insurance', 'Car Insurance'],
  'Retirement Fund': ['Retirement Fund','Retirement Savings'],
  Others: []
};

const getCategoryType = (category) => {
  for (const [type, categories] of Object.entries(categoryMapping)) {
    if (categories.includes(category)) {
      return type;
    }
  }
  return '';
};

const predefinedCategories = [
  'Credit Card Payment',
  'Dining',
  'Groceries',
  'Insurance',
  'Investments',
  'Loan',
  'Long-term Savings',
  'Others',
  'Pension',
  'Personal loan',
  'Rent',
  'Retirement Savings',
  'Shopping',
  'Short-term Savings',
  'Transport',
  'Travel',
  'Utilities'
];

// Add only these new functions/constants at the top of the component
const getDefaultMapping = (category) => {
  for (const [mainCategory, subCategories] of Object.entries(categoryMapping)) {
    if (subCategories.includes(category)) {
      return mainCategory;
    }
  }
  return 'Others';
};

const mainCategories = Object.keys(categoryMapping);

function SpendAnalyzer({ budgetData, onBudgetDataChange }) {
  const [income, setIncome] = useState('');
  const [categories, setCategories] = useState(['']);
  const [amounts, setAmounts] = useState(['']);
  const [error, setError] = useState('');
  const [monthlyIncome, setMonthlyIncome] = useState('');
  const [showIncomeField, setShowIncomeField] = useState(false);
  const navigate = useNavigate();

  const categoryOptions = [...predefinedCategories, 'Custom'];

  // Add state for category mappings
  const [categoryMappings, setCategoryMappings] = useState(['']);

  // Add these state declarations
  const [customCategories, setCustomCategories] = useState([]);
  const [newCustomCategory, setNewCustomCategory] = useState('');

  // Add this state for temporary custom input
  const [customInputs, setCustomInputs] = useState({});

  // Add this state for error message
  const [errorMessage, setErrorMessage] = useState('');

  // Add this state to control Monthly Income section visibility
  const [showMonthlyIncomeSection, setShowMonthlyIncomeSection] = useState(false);

  // Add this useEffect to load custom categories from localStorage on component mount
  useEffect(() => {
    try {
        const savedCustomCategories = localStorage.getItem('customCategories');
        if (savedCustomCategories) {
            const parsedCategories = JSON.parse(savedCustomCategories);
            // Ensure we're setting an array
            setCustomCategories(Array.isArray(parsedCategories) ? parsedCategories : []);
        }
    } catch (error) {
        console.error('Error loading custom categories:', error);
        setCustomCategories([]); // Fallback to empty array if there's an error
    }
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('spendAnalyzerData');
    if (storedData) {
      const { categories, amounts, categoryMappings } = JSON.parse(storedData);
      setCategories(categories);
      setAmounts(amounts);
      setCategoryMappings(categoryMappings || categories.map(() => ''));
    }
  }, []);

  useEffect(() => {
    const savedMonthlyIncome = localStorage.getItem('monthlyIncome');
    if (savedMonthlyIncome) {
      setMonthlyIncome(savedMonthlyIncome);
   }
   

  }, []);
    const saveToLocalStorage = () => {
    const dataToStore = { 
      categories, 
      amounts, 
      categoryMappings 
    };
    localStorage.setItem('spendAnalyzerData', JSON.stringify(dataToStore));
  };

  const handleCategoryChange = (index, value) => {
    const newCategories = [...categories];
    newCategories[index] = value;
    setCategories(newCategories);

    // Add default mapping for predefined categories
    const newMappings = [...categoryMappings];
    if (value !== 'Custom' && value !== '') {
        for (const [type, subcategories] of Object.entries(categoryMapping)) {
            if (subcategories.includes(value)) {
                newMappings[index] = type;
                break;
            }
        }
    }
    setCategoryMappings(newMappings);
    
    saveToLocalStorage();
};

  const handleCustomInput = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const customValue = e.target.value.trim();
      if (customValue) {
        if (!predefinedCategories.includes(customValue)) {
          predefinedCategories.push(customValue);
        }
        const newCategories = [...categories];
        newCategories[index] = customValue;
        setCategories(newCategories);
        
        // Save to localStorage immediately after updating categories
        const dataToStore = {
          categories: newCategories,
          amounts: amounts,
          categoryMappings: categoryMappings
        };
        localStorage.setItem('spendAnalyzerData', JSON.stringify(dataToStore));
        
        const amountInput = e.target.closest('tr').querySelector('.input-number');
        if (amountInput) amountInput.focus();
      }
    }
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = value;
    setAmounts(newAmounts);
    saveToLocalStorage();
  };

  const handleAddCategory = () => {
    setCategories([...categories, '']);
    setAmounts([...amounts, '']);
    setCategoryMappings([...categoryMappings, '']);
    saveToLocalStorage();
  };

  const validateCategories = (categoriesList) => {
    const uniqueCategories = new Set(categoriesList.filter(cat => cat !== '' && cat !== 'Custom'));
    if (uniqueCategories.size !== categoriesList.filter(cat => cat !== '' && cat !== 'Custom').length) {
      setError('Categories must be unique');
    } else {
      setError('');
    }
  };

  const handleIncomeChange = (value) => {
    setIncome(value);
    saveToLocalStorage();
  };

  const handleMonthlyIncomeChange = (e) => {
    const value = e.target.value;
    setMonthlyIncome(value);
    localStorage.setItem('monthlyIncome', value);
  };

  const handleAnalyzeClick = () => {
    console.log('Income:', monthlyIncome);
    console.log('Categories:', categories);
    console.log('Amounts:', amounts);
    
    if (monthlyIncome.trim() === '' || categories.some(cat => cat.trim() === '') || amounts.some(amount => amount.trim() === '')) {
      setError('Please fill out all fields, including income');
    } else {
      const spendData = categories.map((category, index) => ({
        category,
        amount: parseFloat(amounts[index]) || 0,
        categoryMapping: categoryMappings[index]
      }));
      
      saveToLocalStorage();
      localStorage.setItem('monthlyIncome', monthlyIncome);
      navigate('/spend-analysis-summary', { 
        state: { 
          spendData: spendData,
          income: parseFloat(monthlyIncome),
          categories: categories,
          amounts: amounts,
          categoryMappings: categoryMappings
        } 
      });
    }
  };

  const totalAmount = amounts.reduce((acc, val) => acc + parseFloat(val) || 0, 0);

  const uniqueCategories = [...new Set(categories)];
  const categoryMap = new Map();
  uniqueCategories.forEach((category, index) => {
    if (category && amounts[index]) {
      categoryMap.set(category, parseFloat(amounts[index]) || 0);
    }
  });

  const spendingData = {
    labels: [...categoryMap.keys()],
    datasets: [
      {
        label: 'Spending Breakdown',
        data: [...categoryMap.values()],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#D0D0D0', '#C0C0C0', '#FF9F40', '#4BC0C0', '#FFCE56', '#36A2EB'],
        borderColor: '#fff',
        borderWidth: 2,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#333',
          filter: (item) => categoryMap.has(item.text), // Filter out unselected categories
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            const percentage = totalAmount > 0 ? ((value / totalAmount) * 100).toFixed(0) : 0;
            return `${tooltipItem.label}: ${value} (${percentage}%)`;
          },
        },
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
      },
      datalabels: {
        formatter: (value, context) => {
          const percentage = totalAmount > 0 ? ((value / totalAmount) * 100).toFixed(0) : 0;
          return `${percentage}%`;
        },
        color: 'black',
        anchor: 'end',
        align: 'center',
        offset: 5,
        font: {
          weight: 'bold',
        },
      },
    },
    elements: {
      arc: {
        borderColor: '#fff',
      },
    },
  };

  const topCategories = Array.from(categoryMap.entries())
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)
    .map(([category]) => category);

  const recommendations = [
    'Increase savings by 10%',
    `Reduce expenses in the ${topCategories[0] || 'category'} by 10%`,
  ];

  const handleConfirmSpends = () => {
    console.log('Button clicked');
    console.log('Current expenses:', expenses);
    console.log('Custom inputs:', customInputs);

    const hasEmptyFields = expenses.some((exp, index) => {
      // Check both regular category and custom input
      const categoryValue = customInputs[index] || exp.category;
      const isEmpty = !categoryValue || categoryValue === '' || 
                     !exp.amount || exp.amount === '';
      
      console.log(`Checking expense ${index}:`, {
        expense: exp,
        customInput: customInputs[index],
        categoryValue: categoryValue,
        categoryEmpty: !categoryValue || categoryValue === '',
        amountEmpty: !exp.amount || exp.amount === '',
        isEmpty: isEmpty
      });
      
      return isEmpty;
    });

    console.log('Has empty fields:', hasEmptyFields);

    if (hasEmptyFields) {
      setErrorMessage('Please fill all fields before confirming');
      return;
    }

    // If validation passes, update expenses with custom inputs
    const updatedExpenses = expenses.map((exp, index) => ({
      ...exp,
      category: customInputs[index] || exp.category
    }));
    setExpenses(updatedExpenses);
    localStorage.setItem('spendAnalyzerExpenses', JSON.stringify(updatedExpenses));

    setErrorMessage('');
    setShowMonthlyIncomeSection(true);
  };

  const handleDeleteRow = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setCategories(prev => {
      const newCategories = [...prev];
      newCategories.splice(index, 1);
      return newCategories;
    });
    
    setAmounts(prev => {
      const newAmounts = [...prev];
      newAmounts.splice(index, 1);
      return newAmounts;
    });

    setCategoryMappings(prev => {
      const newMappings = [...prev];
      newMappings.splice(index, 1);
      return newMappings;
    });

    if (categories.length === 1) {
      setCategories(['']);
      setAmounts(['']);
      setCategoryMappings(['']);
    }
    
    saveToLocalStorage();
  };

  // Add function to handle mapping change
  const handleMappingChange = (index, value) => {
    const newMappings = [...categoryMappings];
    newMappings[index] = value;
    setCategoryMappings(newMappings);
    saveToLocalStorage();
  };

  // Update the handleAddCustomCategory function to store in localStorage
  const handleAddCustomCategory = () => {
    if (newCustomCategory.trim()) {
        const updatedCategories = Array.isArray(customCategories) 
            ? [...customCategories, newCustomCategory.trim()]
            : [newCustomCategory.trim()];
            
        setCustomCategories(updatedCategories);
        setNewCustomCategory('');
        
        try {
            localStorage.setItem('customCategories', JSON.stringify(updatedCategories));
        } catch (error) {
            console.error('Error saving custom categories:', error);
        }
    }
  };

  // Update the handleRemoveCustomCategory function to update localStorage
  const handleRemoveCustomCategory = (indexToRemove) => {
    if (!Array.isArray(customCategories)) {
        setCustomCategories([]);
        return;
    }
    
    const updatedCategories = customCategories.filter((_, index) => index !== indexToRemove);
    setCustomCategories(updatedCategories);
    
    try {
        localStorage.setItem('customCategories', JSON.stringify(updatedCategories));
    } catch (error) {
        console.error('Error saving custom categories:', error);
    }
  };

  // Update expense state structure
  const [expenses, setExpenses] = useState([
    { category: '', categoryMapping: '', amount: '' }
  ]);

  // Add this useEffect at the top of your component to load data when component mounts
  useEffect(() => {
    const savedExpenses = localStorage.getItem('spendAnalyzerExpenses');
    const savedCustomInputs = localStorage.getItem('spendAnalyzerCustomInputs');
    
    if (savedExpenses) {
      setExpenses(JSON.parse(savedExpenses));
    }
    
    if (savedCustomInputs) {
      setCustomInputs(JSON.parse(savedCustomInputs));
    }
  }, []);

  // Update handleExpenseChange to save to localStorage whenever data changes
  const handleExpenseChange = (index, field, value) => {
    console.log('Handling expense change:', { index, field, value }); 
    
    const updatedExpenses = [...expenses];
    
    if (field === 'category') {
      if (value === 'Custom') {
        const updatedCustomInputs = { ...customInputs, [index]: '' };
        setCustomInputs(updatedCustomInputs);
        localStorage.setItem('spendAnalyzerCustomInputs', JSON.stringify(updatedCustomInputs));
        return;
      }
      
      // Clear custom input if switching from custom
      setCustomInputs(prev => {
        const updated = {...prev};
        delete updated[index];
        return updated;
      });

      // Check for duplicates
      const isDuplicate = expenses.some((exp, i) => 
        i !== index && exp.category === value && value !== ''
      );
      
      if (isDuplicate) {
        setErrorMessage('Categories should be unique');
        return;
      }
      
      updatedExpenses[index].category = value;
      updatedExpenses[index].categoryMapping = getDefaultMapping(value);
    } else {
      updatedExpenses[index][field] = value;
    }
    
    // Ensure the expense object has all required fields
    if (!updatedExpenses[index].category) updatedExpenses[index].category = '';
    if (!updatedExpenses[index].amount) updatedExpenses[index].amount = '';
    
    setExpenses(updatedExpenses);
    localStorage.setItem('spendAnalyzerExpenses', JSON.stringify(updatedExpenses));
    setErrorMessage('');
  };

  // Update handleDeleteExpense to also update localStorage
  const handleDeleteExpense = (index) => {
    const updatedExpenses = expenses.filter((_, i) => i !== index);
    setExpenses(updatedExpenses);
    localStorage.setItem('spendAnalyzerExpenses', JSON.stringify(updatedExpenses));
  };

  // Update handleAddExpense to add a clean new row
  const handleAddExpense = () => {
    // Create a new empty expense object
    const newExpense = {
      category: '',
      categoryMapping: '',
      amount: ''
    };
    
    const updatedExpenses = [...expenses, newExpense];
    setExpenses(updatedExpenses);
    
    // Clear any custom inputs for the new row
    setCustomInputs(prev => {
      const updated = { ...prev };
      delete updated[expenses.length]; // Clear for the new index
      return updated;
    });
    
    localStorage.setItem('spendAnalyzerExpenses', JSON.stringify(updatedExpenses));
  };

  // Add this keyboard handler function
  const handleKeyDown = (event, index, field) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      
      // If it's the amount field, add a new row
      if (field === 'amount') {
        handleAddExpense();
        // Focus on the first field of the new row after a short delay
        setTimeout(() => {
          const inputs = document.querySelectorAll('.input-select, .input-number');
          const nextInput = inputs[inputs.length - 3]; // First field of new row
          if (nextInput) nextInput.focus();
        }, 0);
      } else {
        // Move to next field
        const inputs = document.querySelectorAll('.input-select, .input-number');
        const currentIndex = Array.from(inputs).indexOf(event.target);
        const nextInput = inputs[currentIndex + 1];
        if (nextInput) nextInput.focus();
      }
    }
  };

  // Add handler for custom category input
  const handleCustomCategoryInput = (index, value) => {
    const updatedCustomInputs = { ...customInputs, [index]: value };
    setCustomInputs(updatedCustomInputs);
    localStorage.setItem('spendAnalyzerCustomInputs', JSON.stringify(updatedCustomInputs));
  };

  // Add handler for custom category confirmation
  const handleCustomCategoryConfirm = (index, value) => {
    if (value.trim()) {
      // Update expenses
      const updatedExpenses = [...expenses];
      updatedExpenses[index].category = value.trim();
      setExpenses(updatedExpenses);
      localStorage.setItem('spendAnalyzerExpenses', JSON.stringify(updatedExpenses));
      
      // Clear custom input for this index
      const updatedCustomInputs = { ...customInputs };
      delete updatedCustomInputs[index];
      setCustomInputs(updatedCustomInputs);
      localStorage.setItem('spendAnalyzerCustomInputs', JSON.stringify(updatedCustomInputs));
      
      // Add to custom categories list for future use
      const updatedCustomCategories = [...customCategories, value.trim()];
      setCustomCategories(updatedCustomCategories);
      localStorage.setItem('customCategories', JSON.stringify(updatedCustomCategories));
    }
  };

  const handleConfirmIncome = () => {
    if (monthlyIncome) {
      // Save to localStorage
      localStorage.setItem('monthlyIncome', monthlyIncome);
      
      // Navigate to summary page with data (keep existing navigation logic)
      navigate('/spend-analysis-summary', {
        state: {
          spendData: expenses.map(exp => ({
            ...exp,
            amount: Number(exp.amount)
          })),
          income: monthlyIncome
        }
      });
    }
  };

  return (
    <div className="page-container">
      <div className="progress-section">
        <ProgressBar />
      </div>
      
      <div className="content-container">
        <h2>Spend Analyzer</h2>
        <p className="spend-analyzer-description">
          Track and categorize your expenses to understand your spending patterns.
          Add your expense categories and corresponding amounts below.
        </p>

        <div className="spend-analyzer-wrapper">
          <div className="spend-analyzer-page">
            <div className="scrollable-content">
              <div className="spend-form">
                <table className="spend-analyzer-table">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Category Mapping</th>
                      <th>Amount (₹)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenses.map((expense, index) => (
                      <tr key={index}>
                        <td>
                          {customInputs.hasOwnProperty(index) ? (
                            <input
                              type="text"
                              className="input-select"
                              value={customInputs[index]}
                              onChange={(e) => handleCustomCategoryInput(index, e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleCustomCategoryConfirm(index, customInputs[index]);
                                  handleKeyDown(e, index, 'category');
                                }
                              }}
                              placeholder="Enter custom category"
                              autoFocus
                            />
                          ) : (
                            <select
                              className="input-select"
                              value={expense.category}
                              onChange={(e) => handleExpenseChange(index, 'category', e.target.value)}
                              onKeyDown={(e) => handleKeyDown(e, index, 'category')}
                            >
                              <option value="">Select Category</option>
                              {categoryOptions.map((category) => (
                                <option key={category} value={category}>
                                  {category}
                                </option>
                              ))}
                              {customCategories.map((category) => (
                                <option key={category} value={category}>
                                  {category}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                        <td>
                          <select
                            className="input-select"
                            value={expense.categoryMapping || getDefaultMapping(expense.category)}
                            onChange={(e) => handleExpenseChange(index, 'categoryMapping', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index, 'categoryMapping')}
                          >
                            <option value="">Select Mapping</option>
                            {mainCategories.map((category) => (
                              <option key={category} value={category}>
                                {category}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="number"
                            className="input-number"
                            value={expense.amount}
                            onChange={(e) => handleExpenseChange(index, 'amount', e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index, 'amount')}
                            placeholder="Enter amount"
                          />
                        </td>
                        <td>
                          <button
                            className="delete-button"
                            onClick={() => handleDeleteExpense(index)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="button-container">
                  <button 
                    type="button" 
                    onClick={handleAddExpense} 
                    className="spend-analyzer-add-button"
                  >
                    Add More
                  </button>
                  <button 
                    type="button" 
                    className="analyze-button"
                    onClick={handleConfirmSpends}
                  >
                    Confirm my spends
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Monthly Income section */}
          {showMonthlyIncomeSection && (
            <div className="spend-analyzer-income-section">
              <p>Enter your monthly income</p>
              <input
                type="number"
                value={monthlyIncome}
                onChange={(e) => setMonthlyIncome(e.target.value)}
                className="input-number"
                placeholder="Enter monthly income"
              />
              <button
                type="button"
                onClick={handleConfirmIncome}
                className="analyze-button"
              >
                Confirm my income
              </button>
            </div>
          )}

          {errorMessage && (
            <div className="spend-analyzer-error">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SpendAnalyzer;
