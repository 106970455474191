import React, { useState, useEffect } from 'react';
import '../css/ExpenseTracker.css';
import OpenAI from 'openai';

// Initialize OpenAI client
const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

// Simplified CategoryMap
const CategoryMap = {
    Essentials: ['Rent', 'Groceries', 'Utilities', 'Transport'],
    Discretionary: ['Travel', 'Dining', 'Shopping', 'Subscriptions'],
    Others: [] // This will catch all categories not mapped above
};

// Add a separate constant for all available categories
const ALL_CATEGORIES = [
    // Living Expenses
    'Rent', 'Groceries', 'Utilities', 'Transport',
    // Lifestyle
    'Travel', 'Dining', 'Shopping', 'Subscriptions'
];

// Helper function to find the expense type for a given category
const findExpenseTypeForCategory = (category) => {
    for (const [expenseType, categories] of Object.entries(CategoryMap)) {
        if (categories.includes(category)) {
            return expenseType;
        }
    }
    return 'Others'; // Default if no mapping found
};

const ExpenseTracker = ({ onBudgetDataChange, onSave }) => {
    const [expenses, setExpenses] = useState([]);
    const [customCategories, setCustomCategories] = useState({});
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [adjustedSpends, setAdjustedSpends] = useState(() => {
        const saved = localStorage.getItem('expenseTracker_adjustedSpends');
        return saved ? JSON.parse(saved) : {};
    });
    const [updateMessage, setUpdateMessage] = useState(() => {
        return localStorage.getItem('expenseTracker_updateMessage') || '';
    });
    const [aiInsights, setAiInsights] = useState('');
    const [showInsights, setShowInsights] = useState(false);
    const [showOptimizationTable, setShowOptimizationTable] = useState(false);
    const [showAdjustmentButton, setShowAdjustmentButton] = useState(false);
   
    // Load expenses from localStorage when component mounts
    useEffect(() => {
        const loadData = () => {
            try {
                // Load expenses
                const storedExpenses = JSON.parse(localStorage.getItem('trackedExpenses')) || [];
                setExpenses(storedExpenses);

                // Load custom categories
                const storedCustomCategories = JSON.parse(localStorage.getItem('customCategories')) || {};
                setCustomCategories(storedCustomCategories);

                // Load analysis state
                const storedAnalysis = JSON.parse(localStorage.getItem('expenseAnalysis')) || {
                    showAnalysis: false,
                };
                setShowAnalysis(storedAnalysis.showAnalysis);
             
            } catch (error) {
                console.error('Error loading data from localStorage:', error);
            }
        };

        loadData();
        window.addEventListener('focus', loadData);
        
        return () => {
            window.removeEventListener('focus', loadData);
        };
    }, []);

    // Add a new row with default empty values
    const addExpense = () => {
        const defaultCategory = 'Rent';
        const defaultExpenseType = findExpenseTypeForCategory(defaultCategory);
        
        const newExpense = {
            id: Date.now(),
            date: '',
            name: '',
            amount: '',
            category: defaultCategory,
            expenseType: defaultExpenseType,
            modeOfPayment: 'Credit Card',
            frequency: 'Monthly'
        };
        
        const updatedExpenses = [newExpense, ...expenses];
        setExpenses(updatedExpenses);
        saveToLocalStorage({ expenses: updatedExpenses });
    };

    // Handle edits to table fields
    const handleEditChange = (index, field, value) => {
        try {
            const updatedExpenses = [...expenses];
            
            // Special handling for category field
            if (field === 'category') {
                if (value === 'Other') {
                    const updatedCustomCategories = {
                        ...customCategories,
                        [index]: customCategories[index] || ''
                    };
                    setCustomCategories(updatedCustomCategories);
                    updatedExpenses[index].expenseType = 'Others';
                    
                    saveToLocalStorage({ 
                        expenses: updatedExpenses,
                        customCategories: updatedCustomCategories
                    });
                } else {
                    const updatedCustomCategories = { ...customCategories };
                    delete updatedCustomCategories[index];
                    setCustomCategories(updatedCustomCategories);
                    
                    const expenseType = findExpenseTypeForCategory(value);
                    updatedExpenses[index].expenseType = expenseType;
                    
                    saveToLocalStorage({ 
                        expenses: updatedExpenses,
                        customCategories: updatedCustomCategories
                    });
                }
            }
            
            updatedExpenses[index][field] = value;
            setExpenses(updatedExpenses);
            saveToLocalStorage({ expenses: updatedExpenses });
            
        } catch (error) {
            console.error('Error saving edit:', error);
        }
    };

    // Add a handler for custom category input
    const handleCustomCategoryChange = (index, value) => {
        try {
            const updatedExpenses = [...expenses];
            
            if (!value.trim()) {
                // If the custom input is empty, revert back to dropdown
                updatedExpenses[index].category = '';
            } else {
                updatedExpenses[index].category = 'Other';
                updatedExpenses[index].expenseType = 'Others';
            }
            
            setExpenses(updatedExpenses);
            
            setCustomCategories(prev => ({
                ...prev,
                [index]: value
            }));
            
            localStorage.setItem('trackedExpenses', JSON.stringify(updatedExpenses));
            localStorage.setItem('customCategories', JSON.stringify(customCategories));
        } catch (error) {
            console.error('Error saving custom category:', error);
        }
    };

    // Handle explicit save with sorting
    const handleSaveChanges = () => {
        try {
            // Sort expenses by date (latest first)
            const sortedExpenses = [...expenses].sort((a, b) => {
                const dateA = a.date ? new Date(a.date) : new Date(0);
                const dateB = b.date ? new Date(b.date) : new Date(0);
                return dateB - dateA;
            });

            // Calculate total amount of all expenses
            const totalAmount = sortedExpenses.reduce((total, expense) => {
                const amount = parseFloat(expense.amount) || 0;
                return total + amount;
            }, 0);

            // Update the total spend amount in budget data
            onBudgetDataChange('Expense Reduction', totalAmount);

            // Save to localStorage
            localStorage.setItem('trackedExpenses', JSON.stringify(sortedExpenses));
            localStorage.setItem('totalExpenses', totalAmount.toString());

            // Update state
            setExpenses(sortedExpenses);

            console.log('Saved and sorted expenses:', sortedExpenses);
            console.log('Total amount:', totalAmount);

            
        } catch (error) {
            console.error('Error saving changes:', error);
            alert('Error saving changes');
        }
    };

    // Add delete handler function
    const handleDelete = (index) => {
        try {
            const updatedExpenses = expenses.filter((_, idx) => idx !== index);
            const updatedCustomCategories = { ...customCategories };
            delete updatedCustomCategories[index];
            
            setExpenses(updatedExpenses);
            setCustomCategories(updatedCustomCategories);
            
            saveToLocalStorage({ 
                expenses: updatedExpenses,
                customCategories: updatedCustomCategories
            });
            
        } catch (error) {
            console.error('Error deleting expense:', error);
        }
    };

    // Add function to calculate spend breakdown
    const calculateSpendBreakdown = () => {
        const breakdown = {};
        let totalSpend = 0;
        
        // Get category totals from SpendAnalyzer
        const categoryTotals = JSON.parse(localStorage.getItem('categoryTotals')) || {};

        // Initialize breakdown with all categories
        ALL_CATEGORIES.forEach(category => {
            breakdown[category] = {
                amount: 0,
                percentage: 0,
                budget: categoryTotals[category] || 0,
                variance: 0
            };
        });

        // Calculate totals by category
        expenses.forEach(expense => {
            const amount = parseFloat(expense.amount) || 0;
            const category = expense.category;
            
            if (category && category !== 'Other' && amount > 0) {
                if (!breakdown[category]) {
                    breakdown[category] = {
                        amount: 0,
                        percentage: 0,
                        budget: categoryTotals[category] || 0,
                        variance: 0
                    };
                }
                breakdown[category].amount += amount;
                totalSpend += amount;
            }
        });

        // Calculate percentages and variances
        Object.keys(breakdown).forEach(category => {
            const data = breakdown[category];
            data.percentage = totalSpend > 0 ? ((data.amount / totalSpend) * 100).toFixed(2) : '0.00';
            data.variance = data.budget - data.amount;
        });

        // Filter out categories with no budget and no spending
        const filteredBreakdown = Object.entries(breakdown)
            .filter(([_, data]) => data.budget > 0 || data.amount > 0)
            .sort(([a], [b]) => a.localeCompare(b))
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        return { breakdown: filteredBreakdown, totalSpend };
    };

    // Add this new function to get AI insights
    const getAIInsights = async (expenses) => {
        try {
            // First, create valid expense data
            const validExpenses = expenses
                .filter(expense => expense.amount && expense.category) // Filter out invalid entries
                .map(expense => ({
                    name: expense.name,
                    amount: parseFloat(expense.amount) || 0,
                    category: expense.category,
                    frequency: expense.frequency,
                    expenseType: expense.expenseType
                }));

            // Group and sum expenses by category
            const groupedExpenses = validExpenses.reduce((acc, expense) => {
                const key = expense.category;
                if (!acc[key]) {
                    acc[key] = {
                        category: expense.category,
                        amount: 0,
                        frequency: new Set(),
                        expenseType: expense.expenseType
                    };
                }
                acc[key].amount += expense.amount;
                acc[key].frequency.add(expense.frequency);
                return acc;
            }, {});

            // Convert grouped data to array format and convert frequency Set to Array
            const groupedExpenseArray = Object.values(groupedExpenses).map(expense => ({
                category: expense.category,
                amount: expense.amount,
                frequency: Array.from(expense.frequency).join('/'), // Combine multiple frequencies
                expenseType: expense.expenseType
            }));

            // Filter out essential categories
            const nonEssentialExpenses = groupedExpenseArray.filter(
                expense => !CategoryMap.Essentials.includes(expense.category)
            );

            // If no non-essential expenses, return early
            if (nonEssentialExpenses.length === 0) {
                return "No non-essential expenses found to analyze.";
            }

            const prompt = `As a financial advisor, analyze these grouped non-essential expenses and provide insights based on the total spending in each category:
            ${JSON.stringify(nonEssentialExpenses, null, 2)}

            Provide insights ONLY for categories present in the data above. 
            Each insight should be a single, complete sentence including specific amounts.
            
            Consider these aspects (but only include if relevant data exists):
            - Categories with highest total spending
            - Categories with multiple frequency types (daily/monthly/adhoc)
            - Potential for consolidation or reduction in each category

            Format each insight as:
            1. "Consider reducing [category] spending from ₹[amount] by [specific action] to save ₹[amount] monthly."

            Important:
            - Only suggest optimizations for categories present in the data
            - Number of insights should match the available patterns (can be less than 4)
            - Each insight must reference the total spending amount for each category
            - Focus on categories with highest spending first`;

            const completion = await openai.chat.completions.create({
                messages: [{ role: "user", content: prompt }],
                model: "gpt-4o",
            });

            return completion.choices[0].message.content;
        } catch (error) {
            console.error('Error getting AI insights:', error);
            return null;
        }
    };

    // Update the handleAnalyzeExpenses function
    const handleAnalyzeExpenses = async () => {
        setShowAnalysis(true);
        setShowInsights(true);
        setShowOptimizationTable(true);
        setShowAdjustmentButton(true);
        saveToLocalStorage({ analysis: { showAnalysis: true } });

        // Get AI insights
        const insights = await getAIInsights(expenses);
        if (insights) {
            setAiInsights(insights);
        }
    };

    // Add a function to calculate budget utilization percentage
    const calculateBudgetUtilization = (spent, budget) => {
        if (budget <= 0) return 0;
        return ((budget - spent) / budget) * 100;
    };

    // Add a function to save all states to localStorage
    const saveToLocalStorage = (updates = {}) => {
        try {
            // Save expenses if they've changed
            if (updates.expenses) {
                localStorage.setItem('trackedExpenses', JSON.stringify(updates.expenses));
            }

            // Save custom categories if they've changed
            if (updates.customCategories) {
                localStorage.setItem('customCategories', JSON.stringify(updates.customCategories));
            }

            // Save analysis states
            const analysisState = {
                showAnalysis,
                ...updates.analysis
            };
            localStorage.setItem('expenseAnalysis', JSON.stringify(analysisState));

            // Save breakdown data if available
            if (showAnalysis) {
                const breakdownData = calculateSpendBreakdown();
                localStorage.setItem('expenseBreakdown', JSON.stringify(breakdownData));
            }

        } catch (error) {
            console.error('Error saving to localStorage:', error);
        }
    };

    // Add these handler functions before the return statement
    const handlePercentageReduction = (percentage) => {
        try {
            const updatedExpenses = expenses.map(expense => {
                // Only reduce discretionary spending categories
                if (['Travel', 'Dining', 'Shopping', 'Subscriptions'].includes(expense.category)) {
                    const amount = parseFloat(expense.amount);
                    if (!isNaN(amount)) {
                        return {
                            ...expense,
                            amount: (amount * (1 - percentage / 100)).toFixed(2)
                        };
                    }
                }
                return expense;
            });

            setExpenses(updatedExpenses);
            saveToLocalStorage({ expenses: updatedExpenses });
            
        } catch (error) {
            console.error('Error applying percentage reduction:', error);
        }
    };

    // Add this new function before the return statement
    const generateOptimizationTable = () => {
        // Group expenses by category
        const categoryTotals = expenses.reduce((acc, expense) => {
            const category = expense.category;
            if (!acc[category]) {
                acc[category] = 0;
            }
            acc[category] += parseFloat(expense.amount) || 0;
            return acc;
        }, {});

        // Convert to array format
        return Object.entries(categoryTotals).map(([category, amount]) => ({
            category,
            amount
        }));
    };

    // Update the handler for adjusted spends
    const handleAdjustedSpendChange = (category, value) => {
        // Allow empty string for clearing the input
        const numValue = value === '' ? '' : parseFloat(value);
        setAdjustedSpends(prev => ({
            ...prev,
            [category]: numValue
        }));
    };

    // Add this handler function
    const handleAddAdjustmentsToBudget = () => {
        try {
            // Calculate total of all adjusted spends
            const newSpends = generateOptimizationTable()
                .reduce((total, { category, amount }) => {
                    // Use adjustedSpends value if available, otherwise use original amount
                    const adjustedAmount = adjustedSpends[category] ?? amount;
                    return total + adjustedAmount;
                }, 0);

            // Update the Aggregator
            onBudgetDataChange('Expense Reduction', newSpends);

            // Update message with the new total
            const message = `Budget successfully updated! New total spends: ₹${newSpends.toFixed(2)}`;
            setUpdateMessage(message);

            console.log('New total spends calculated from adjusted values:', {
                newSpends,
                adjustedSpends
            });

            // Enable the Next button by calling onSave
            if (onSave) {
                onSave();
            }
        } catch (error) {
            console.error('Error updating budget with adjustments:', error);
            setUpdateMessage('Failed to update budget with adjustments.');
        }
    };

    // Update the handleApplyOptimizations function
    const handleApplyOptimizations = () => {
        try {
            // Parse existing adjusted spends
            const updatedAdjustedSpends = { ...adjustedSpends };
    
            // Log the AI insights for debugging
            console.log('AI Insights:', aiInsights);
    
            // Split insights into lines and filter empty ones
            const insights = aiInsights.split('\n').filter((insight) => insight.trim());
            console.log('Processed Insights:', insights);
    
            // Process each insight
            insights.forEach((insight) => {
                console.log('Processing insight:', insight);
    
                // Try to match the AI-generated insight pattern
                let matches = insight.match(/reducing (\w+) spending from ₹(\d+(?:\.\d+)?).+save ₹(\d+(?:\.\d+)?)/i);
    
                // If the first pattern doesn't match, try another
                if (!matches) {
                    matches = insight.match(/(\w+).+₹(\d+(?:\.\d+)?).+save ₹(\d+(?:\.\d+)?)/i);
                }
    
                if (matches) {
                    const [, category, currentSpendStr, savingsStr] = matches;
                    const currentSpend = parseFloat(currentSpendStr);
                    const suggestedSavings = parseFloat(savingsStr);
    
                    // Find the matching category in the optimization table
                    const tableEntry = generateOptimizationTable().find(
                        (item) => item.category.toLowerCase() === category.toLowerCase()
                    );
    
                    if (tableEntry && !CategoryMap.Essentials.includes(tableEntry.category)) {
                        const adjustedAmount = updatedAdjustedSpends[tableEntry.category] ?? tableEntry.amount;
    
                        // Calculate the new adjusted spend
                        const newAdjustedSpend = Math.max(adjustedAmount - suggestedSavings, 0);
    
                        // Update the adjusted spends
                        updatedAdjustedSpends[tableEntry.category] = newAdjustedSpend;
    
                        console.log('Updated category:', {
                            category: tableEntry.category,
                            currentSpend: adjustedAmount,
                            suggestedSavings,
                            newAdjustedSpend,
                        });
                    }
                } else {
                    console.log('No match found for insight:', insight);
                }
            });
    
            // Log the final adjusted spends
            console.log('Final adjusted spends:', updatedAdjustedSpends);
    
            // Update the adjusted spends state and localStorage
            setAdjustedSpends(updatedAdjustedSpends);
            localStorage.setItem('expenseTracker_adjustedSpends', JSON.stringify(updatedAdjustedSpends));
    
            // Show a success message
            setUpdateMessage('AI-suggested optimizations applied successfully!');
        } catch (error) {
            console.error('Error in handleApplyOptimizations:', error);
            setUpdateMessage('Failed to apply optimizations. Check console for details.');
        }
    };
    

    useEffect(() => {
        localStorage.setItem('expenseTracker_adjustedSpends', JSON.stringify(adjustedSpends));
    }, [adjustedSpends]);

    useEffect(() => {
        localStorage.setItem('expenseTracker_updateMessage', updateMessage);
    }, [updateMessage]);

    return (
        <div className="expense-tracker-expensetracker">
            <div className="expense-description">
                <div className="expense-description-header">
                    <h2>Expense Tracker</h2>
                    <p className="expense-description-subtitle">Track, analyze, and optimize your spending habits</p>
                </div>
                <div className="expense-description-features">
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">📊</span>
                        <span>Track daily expenses</span>
                    </div>
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">🤖</span>
                        <span>AI-powered insights</span>
                    </div>
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">💡</span>
                        <span>Smart recommendations</span>
                    </div>
                    <div className="expense-feature-item">
                        <span className="expense-feature-icon">📈</span>
                        <span>Budget analysis</span>
                    </div>
                </div>
            </div>

            {/* Editable Table */}
            <table className="table-expensetracker">
                <thead>
                    <tr>
                        <th className="th-expensetracker">Date</th>
                        <th className="th-expensetracker">Expense Name</th>
                        <th className="th-expensetracker">Amount</th>
                        <th className="th-expensetracker">Expense Category</th>
                        <th className="th-expensetracker">Expense Type</th>
                        <th className="th-expensetracker">Mode of Payment</th>
                        <th className="th-expensetracker">Frequency</th>
                        <th className="th-expensetracker">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {expenses.map((expense, index) => (
                        <tr key={expense.id || index} className="tr-expensetracker">
                            <td className="td-expensetracker">
                                <input
                                    type="date"
                                    value={expense.date}
                                    onChange={(e) => handleEditChange(index, 'date', e.target.value)}
                                    className="input-expensetracker"
                                />
                            </td>
                            <td className="td-expensetracker">
                                <input
                                    type="text"
                                    value={expense.name}
                                    onChange={(e) => handleEditChange(index, 'name', e.target.value)}
                                    placeholder="Expense Name"
                                    className="input-expensetracker"
                                />
                            </td>
                            <td className="td-expensetracker">
                                <input
                                    type="number"
                                    value={expense.amount}
                                    onChange={(e) => handleEditChange(index, 'amount', e.target.value)}
                                    placeholder="Amount"
                                    className="input-expensetracker"
                                    min="0"
                                    step="0.01"
                                />
                            </td>
                            <td className="td-expensetracker">
                                {expense.category === 'Other' && customCategories[index]?.trim() ? (
                                    <input
                                        type="text"
                                        value={customCategories[index] || ''}
                                        onChange={(e) => handleCustomCategoryChange(index, e.target.value)}
                                        placeholder="Enter custom category"
                                        className="input-expensetracker"
                                    />
                                ) : (
                                    <select
                                        value={expense.category}
                                        onChange={(e) => handleEditChange(index, 'category', e.target.value)}
                                        className="input-expensetracker"
                                    >
                                        <option value="">Select Category</option>
                                        {ALL_CATEGORIES.map(category => (
                                            <option key={category} value={category}>{category}</option>
                                        ))}
                                        <option value="Other">Other (Custom)</option>
                                    </select>
                                )}
                            </td>
                            <td className="td-expensetracker">
                                <select
                                    value={expense.expenseType}
                                    onChange={(e) => handleEditChange(index, 'expenseType', e.target.value)}
                                    className="input-expensetracker"
                                >
                                    <option value="Essentials">Essentials</option>
                                    <option value="Discretionary">Discretionary</option>
                                    <option value="Others">Others</option>
                                </select>
                            </td>
                            <td className="td-expensetracker">
                                <select
                                    value={expense.modeOfPayment}
                                    onChange={(e) => handleEditChange(index, 'modeOfPayment', e.target.value)}
                                    className="input-expensetracker"
                                >
                                    <option value="Credit Card">Credit Card</option>
                                    <option value="UPI">UPI</option>
                                    <option value="Debit Card">Debit Card</option>
                                    <option value="Wallet">Wallet</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Net Banking">Net Banking</option>
                                </select>
                            </td>
                            <td className="td-expensetracker">
                                <select
                                    value={expense.frequency}
                                    onChange={(e) => handleEditChange(index, 'frequency', e.target.value)}
                                    className="input-expensetracker"
                                >
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Yearly">Yearly</option>
                                    <option value="One time">One time</option>
                                    <option value="Adhoc">Adhoc</option>
                                    <option value="Other">Other</option>
                                </select>
                            </td>
                            <td className="td-expensetracker">
                                <button
                                    onClick={() => handleDelete(index)}
                                    className="delete-button-expensetracker"
                                    title="Delete"
                                >
                                    ❌
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Buttons */}
            <div className="button-container-expensetracker">
                <button className="button-add-expense-expensetracker" onClick={addExpense}>
                    Add Expense
                </button>
                <button 
                    className="button-save-changes-expensetracker" 
                    onClick={handleSaveChanges}
                >
                    Save Changes
                </button>
                <button 
                    className="button-analyze-expenses-expensetracker" 
                    onClick={handleAnalyzeExpenses}
                >
                    Analyze
                </button>
            </div>

            {showAnalysis && (
                <>
                    {showInsights && (
                        <div className="insights-container">
                            {Object.entries(calculateSpendBreakdown().breakdown).map(([category, data]) => {
                                const variance = data.variance;
                                const budget = data.budget;
                                const spent = data.amount;
                                let insightClass = '';
                                let message = '';

                                if (variance < 0) {
                                    // Exceeded budget
                                    insightClass = 'exceeded-budget';
                                    message = `⚠️ ${category}: Exceeded budget by ${Math.abs(variance).toFixed(2)} 
                                             (Budget: ₹${budget.toFixed(2)}, Spent: ₹${spent.toFixed(2)})`;
                                } else if (variance > 0 && spent > 0) {
                                    // Under budget with spending
                                    insightClass = 'within-budget';
                                    message = `✅ ${category}: Within budget by ₹${variance.toFixed(2)} 
                                             (Budget: ₹${budget.toFixed(2)}, Spent: ₹${spent.toFixed(2)})`;
                                } else if (budget > 0 && spent === 0) {
                                    // Has budget but no spending
                                    insightClass = 'excess-budget';
                                    message = `⚠️ ${category}: Unused budget of ₹${budget.toFixed(2)} 
                                             (No spending recorded)`;
                                }

                                return message ? (
                                    <div key={category} className={`insight-item ${insightClass}`}>
                                        {message}
                                    </div>
                                ) : null;
                            })}

                            {/* AI Insights Section with Apply button */}
                            {aiInsights && (
                                <div className="ai-insights-section">
                                    <h3>AI-Powered Savings Recommendations</h3>
                                    <div className="ai-insights-content">
                                        {aiInsights.split('\n').map((insight, index) => (
                                            insight.trim() && (
                                                <div key={index} className="ai-insight-item">
                                                    {insight}
                                                </div>
                                            )
                                        ))}
                                    </div>
                                    <button 
                                        className="button-apply-optimizations"
                                        onClick={handleApplyOptimizations}
                                    >
                                        Apply Optimizations
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {showOptimizationTable && (
                        <div className="optimization-table-container">
                            <h3>Spending Optimization Suggestions</h3>
                            <table className="optimization-table">
                                <thead>
                                    <tr>
                                        <th>Expense Category</th>
                                        <th>Current Spend</th>
                                        <th>Adjusted Spends</th>
                                        <th>Potential Savings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {generateOptimizationTable().map(({ category, amount }) => {
                                        const adjustedAmount = adjustedSpends[category] ?? amount;
                                        const actualSavings = amount - adjustedAmount;

                                        return (
                                            <tr key={category}>
                                                <td>{category}</td>
                                                <td>₹{amount.toFixed(0)}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={adjustedAmount.toString()}
                                                        onChange={(e) => handleAdjustedSpendChange(category, e.target.value)}
                                                        className="adjusted-spend-input"
                                                        onBlur={(e) => {
                                                            if (e.target.value === '' || isNaN(parseFloat(e.target.value))) {
                                                                handleAdjustedSpendChange(category, amount);
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>₹{actualSavings.toFixed(0)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr className="total-row">
                                        <td colSpan="3"><strong>Total Savings</strong></td>
                                        <td>
                                            <strong>₹{generateOptimizationTable()
                                                .reduce((total, { category, amount }) => {
                                                    const adjustedAmount = adjustedSpends[category] || amount;
                                                    return total + (amount - adjustedAmount);
                                                }, 0).toFixed(0)}
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}

                    {showAdjustmentButton && (
                        <div className="adjustment-button-container">
                            <button 
                                className="button-add-adjustments"
                                onClick={handleAddAdjustmentsToBudget}
                            >
                                Add Adjustments to Budget
                            </button>
                            {updateMessage && (
                                <div className="update-message">
                                    {updateMessage}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ExpenseTracker;
