import React, { useEffect, useState } from 'react';
import '../css/BudgetSummary.css';

function BudgetSummary({ budgetData, onNetIncomeChange }) {
  // Define fixed categories and state for priorities
  const earningsCategories = ['Earnings'];
  const [expensesCategories, setExpensesCategories] = useState([]);

  // Load priorities from localStorage on component mount
  useEffect(() => {
    const sortedPriorities = JSON.parse(localStorage.getItem('financialPriorities') || '[]');
    console.log('Sorted priorities:', sortedPriorities);
    if (sortedPriorities.length > 0) {
      // Extract categoryNames from sortedPriorities and filter out Insurance and Long-term Savings
      const categories = sortedPriorities
        .map(item => item.content)
        .filter(category => 
          category !== 'Insurance' && 
          category !== 'Long-term Savings'
        );
      setExpensesCategories(categories);
    }
  }, []);

  // Calculate totals
  const totalEarnings = earningsCategories.reduce(
    (total, category) => total + (budgetData[category] || 0),
    0
  );
  const totalExpenses = expensesCategories.reduce(
    (total, category) => total + (budgetData[category] || 0),
    0
  );
  const netIncome = totalEarnings - totalExpenses;

  // Pass netIncome to parent component whenever it changes
  useEffect(() => {
    if (onNetIncomeChange) {
      onNetIncomeChange(netIncome);
    }
  }, [netIncome, onNetIncomeChange]);

  // Determine the class for the net income row based on its value
  const netIncomeClass = netIncome < 0 ? 'net-income-negative' : 'net-income-positive';

  // Add this function to map category names
  const getCategoryDisplayName = (category) => {
    switch (category) {
      case 'Debt Reduction':
        return 'Debt Repayment';
      case 'Expense Reduction':
        return 'Expenses';
      default:
        return category;
    }
  };

  // Format budget data for Conclusions
  useEffect(() => {
    const formattedBudgetData = {
      earnings: {
        categories: earningsCategories,
        amounts: earningsCategories.map(category => ({
          category: getCategoryDisplayName(category),
          amount: Number(budgetData[category]) || 0
        })),
        total: totalEarnings
      },
      expenses: {
        categories: expensesCategories,
        amounts: expensesCategories
          .filter(category => 
            budgetData[category] > 0 && 
            category !== 'Insurance' && 
            category !== 'Long-term Savings'
          )
          .map(category => ({
            category: getCategoryDisplayName(category),
            amount: Number(budgetData[category]) || 0
          })),
        total: totalExpenses
      },
      netIncome: netIncome
    };

    // Save formatted data to localStorage
    localStorage.setItem('budgetSummary', JSON.stringify(formattedBudgetData));
  }, [
    budgetData, 
    earningsCategories, 
    expensesCategories, 
    totalEarnings, 
    totalExpenses, 
    netIncome
  ]);

  return (
    <div className="budget-summary">
      <h3>Budget Summary</h3>
      <table className="budget-summary-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {/* Earnings Section */}
          <tr className="section-header">
            <td colSpan="2">Earnings</td>
          </tr>
          {earningsCategories.map((category) => (
            <tr key={category}>
              <td>{getCategoryDisplayName(category)}</td>
              <td>₹{budgetData[category] ? budgetData[category].toFixed(2) : '0.00'}</td>
            </tr>
          ))}
          
          {/* Expenses Section */}
          <tr className="section-header">
            <td colSpan="2">Expenses</td>
          </tr>
          {expensesCategories.map((category) => (
            <tr key={category}>
              <td>{getCategoryDisplayName(category)}</td>
              <td>₹{budgetData[category] ? budgetData[category].toFixed(2) : '0.00'}</td>
            </tr>
          ))}
          
          {/* Net Income Section */}
          <tr className={`net-income-row ${netIncomeClass}`}>
            <td colSpan="2">
              <strong>Net Income: ₹{netIncome.toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BudgetSummary;
