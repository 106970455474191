import React from 'react';
function Retirement() {
  return (
    <div className="retirement-container">
      <h3>Retirement Planning</h3>
      {/* Add your retirement planning content here */}
      <p>Here you can plan for your retirement savings and investments.</p>
    </div>
  );
}
export default Retirement;