import React, { useState, useEffect } from 'react';
import '../css/ShortTermSavings.css'; // Ensure this is included

const ShortTermSavings = ({ onShortTermSavingsUpdate, onBudgetDataChange, onSave }) => {
  const [goals, setGoals] = useState([]);
  const [pausedGoals, setPausedGoals] = useState([]); // State for paused goals
  const [abandonedGoals, setAbandonedGoals] = useState([]); // State for abandoned goals
  const [completedGoals, setCompletedGoals] = useState([]); // State for completed goals
  const [newGoal, setNewGoal] = useState({ name: '', amount: '', timeframe: '', currentSavings: '', priority: 'Medium' });
  const [isEditing, setIsEditing] = useState(null); // Track which row is being edited
  const [totalContribution, setTotalContribution] = useState(0); // State to hold total contributions

  // State to manage which dropdown is open
  const [openDropdown, setOpenDropdown] = useState(null); // null means no dropdown is open

  // Load goals, paused goals, abandoned goals, and completed goals from localStorage when the component mounts
  useEffect(() => {
    const storedGoals = JSON.parse(localStorage.getItem('shortTermGoals')) || [];
    const storedPausedGoals = JSON.parse(localStorage.getItem('pausedGoals')) || [];
    const storedAbandonedGoals = JSON.parse(localStorage.getItem('abandonedGoals')) || [];
    const storedCompletedGoals = JSON.parse(localStorage.getItem('completedGoals')) || [];

    setGoals(storedGoals); // Set goals state with stored goals
    setPausedGoals(storedPausedGoals); // Set paused goals state
    setAbandonedGoals(storedAbandonedGoals); // Set abandoned goals state
    setCompletedGoals(storedCompletedGoals); // Set completed goals state
    calculateTotalContributions(); // Calculate total contributions on load
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoal({ ...newGoal, [name]: value });
  };

  const handleContributionChange = (e, index) => {
    const updatedGoals = [...goals];
    updatedGoals[index].monthlyContribution = e.target.value;
    setGoals(updatedGoals);
    calculateTotalContributions(); // Recalculate total contributions after changing contribution
    handleSaveDetails(); // Auto-save after changing contribution
  };

  const handlePriorityChange = (e, index) => {
    const updatedGoals = [...goals];
    updatedGoals[index].priority = e.target.value; // Update priority
    setGoals(updatedGoals);
    handleSaveDetails(); // Auto-save after changing priority
  };

  const addGoal = (e) => {
    e.preventDefault();
    if (newGoal.name && newGoal.amount && newGoal.timeframe) {
      const monthlyContribution = calculateMonthlyContribution(newGoal.amount, newGoal.currentSavings, newGoal.timeframe);
      const updatedGoals = [...goals, { ...newGoal, monthlyContribution, paused: false, priority: 'Medium' }]; // Default priority to Medium
      setGoals(updatedGoals); // Update the component state
      setNewGoal({ name: '', amount: '', timeframe: '', currentSavings: '', priority: 'Medium' }); // Reset input fields
      calculateTotalContributions(); // Recalculate total contributions after adding a goal
      handleSaveDetails(); // Auto-save after adding a goal
    }
  };

  const calculateMonthlyContribution = (amount, currentSavings, timeframe) => {
    const totalGoal = parseFloat(amount) - parseFloat(currentSavings);
    return totalGoal > 0 && parseInt(timeframe) > 0 ? (totalGoal / parseInt(timeframe)).toFixed(2) : 0;
  };

  const calculateTotalContributions = () => {
    // If there are no goals, set total contribution to 0
    if (goals.length === 0) {
      setTotalContribution(0);
      onShortTermSavingsUpdate(0); // Notify the Aggregator component about the updated total
      return;
    }

    const total = goals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const pausedTotal = pausedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const abandonedTotal = abandonedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const finalTotal = total - pausedTotal - abandonedTotal; // Include paused and abandoned contributions
    setTotalContribution(finalTotal.toFixed(2)); // Set total contributions
    onShortTermSavingsUpdate(finalTotal); // Notify the Aggregator component about the updated total
  };

  const handleDeleteGoal = (index) => {
    const goalToAbandon = { ...goals[index], monthlyContribution: 0 }; // Set monthly contribution to 0
    const updatedGoals = goals.filter((_, i) => i !== index);
    
    // Update state
    setGoals(updatedGoals);
    setAbandonedGoals(prevAbandonedGoals => [...prevAbandonedGoals, goalToAbandon]);

    // Calculate the new total after deletion
    const newTotal = updatedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const pausedTotal = pausedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const abandonedTotal = abandonedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const finalTotal = newTotal - pausedTotal - abandonedTotal;

    // Update the totals in parent components
    onShortTermSavingsUpdate(finalTotal);
    onBudgetDataChange('Short-term Savings', finalTotal);

    // Save to localStorage
    localStorage.setItem('shortTermGoals', JSON.stringify(updatedGoals));
    localStorage.setItem('abandonedGoals', JSON.stringify([...abandonedGoals, goalToAbandon]));

    // Mark as saved
    if (onSave) {
        onSave();
    }
  };

  const handleSaveDetails = () => {
    // Save the current state to local storage
    localStorage.setItem('shortTermGoals', JSON.stringify(goals));
    localStorage.setItem('pausedGoals', JSON.stringify(pausedGoals));
    localStorage.setItem('abandonedGoals', JSON.stringify(abandonedGoals));
    localStorage.setItem('completedGoals', JSON.stringify(completedGoals));

    // Calculate final total
    const total = goals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const pausedTotal = pausedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const abandonedTotal = abandonedGoals.reduce((sum, goal) => sum + parseFloat(goal.monthlyContribution || 0), 0);
    const finalTotal = total - pausedTotal - abandonedTotal;

    // Update the total in parent components
    onShortTermSavingsUpdate(finalTotal);
    onBudgetDataChange('Short-term Savings', finalTotal);
    
    // Mark the goal as saved to enable the Next button
    onSave();
  };

  const handlePauseGoal = (index) => {
    const updatedGoals = [...goals];
    const goalToPause = updatedGoals[index];

    // Move the goal to paused goals and set monthly contribution to 0
    goalToPause.paused = true;
    goalToPause.monthlyContribution = 0; // Set to 0 when paused

    setPausedGoals([...pausedGoals, goalToPause]); // Add to paused goals
    setGoals(updatedGoals.filter((_, i) => i !== index)); // Remove from active goals
    calculateTotalContributions(); // Recalculate total contributions
    handleSaveDetails(); // Auto-save after pausing a goal
  };

  const handleResumeGoal = (index) => {
    const updatedPausedGoals = [...pausedGoals];
    const goalToResume = updatedPausedGoals[index];

    // Move the goal back to active goals
    goalToResume.paused = false;

    const updatedGoals = [...goals, goalToResume]; // Add back to active goals
    setGoals(updatedGoals);
    setPausedGoals(updatedPausedGoals.filter((_, i) => i !== index)); // Remove from paused goals
    calculateTotalContributions(); // Recalculate total contributions
    handleSaveDetails(); // Auto-save after resuming a goal
  };

  // Function to calculate progress percentage
  const calculateProgress = (goal) => {
    const totalAmount = parseFloat(goal.amount) || 0;
    const currentSavings = parseFloat(goal.currentSavings) || 0;
    return totalAmount > 0 ? (currentSavings / totalAmount) * 100 : 0;
  };

  // Move completed goals to the completed section
  const checkCompletedGoals = () => {
    const newCompletedGoals = goals.filter(goal => calculateProgress(goal) === 100);
    const updatedGoals = goals.filter(goal => calculateProgress(goal) < 100);
    
    if (newCompletedGoals.length > 0) {
      setCompletedGoals(prev => [...prev, ...newCompletedGoals]);
      setGoals(updatedGoals);
      localStorage.setItem('completedGoals', JSON.stringify([...completedGoals, ...newCompletedGoals])); // Save completed goals
      handleSaveDetails(); // Auto-save when completed goals are added

      // Show alert for completed goals
      alert("Congratulations on completing your goals!");
    }
  };

  // Call checkCompletedGoals whenever goals change
  useEffect(() => {
    checkCompletedGoals();
  }, [goals]);

  // Function to toggle dropdowns
  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <div className="short-term-savings">
      <h2>Short-Term Savings Goals</h2>
      <form onSubmit={addGoal}>
        <div className="input-row">
          <input
            type="text"
            name="name"
            value={newGoal.name}
            onChange={handleInputChange}
            placeholder="Goal name"
            required
          />
          <input
            type="number"
            name="amount"
            value={newGoal.amount}
            onChange={handleInputChange}
            placeholder="Amount to save"
            required
          />
          <input
            type="number"
            name="timeframe"
            value={newGoal.timeframe}
            onChange={handleInputChange}
            placeholder="Timeframe (months)"
            required
          />
          <input
            type="number"
            name="currentSavings"
            value={newGoal.currentSavings}
            onChange={handleInputChange}
            placeholder="Current savings"
            required
          />
          <button type="submit">Add Goal</button>
        </div>
      </form>
      {/* Main Goals Table */}
      <table>
        <thead>
          <tr>
            <th>Goal Name</th>
            <th>Amount to Save</th>
            <th>Timeframe (months)</th>
            <th>Current Savings</th>
            <th>Progress</th>
            <th>Priority</th>
            <th>Monthly Contribution</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {goals.map((goal, index) => (
            <tr key={index}>
              <td>{goal.name}</td>
              <td>₹{goal.amount}</td>
              <td>{goal.timeframe}</td>
              <td>₹{goal.currentSavings}</td>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px', marginRight: '10px' }}>
                    <div 
                      style={{
                        width: `${calculateProgress(goal)}%`,
                        backgroundColor: '#76c7c0',
                        height: '10px',
                        borderRadius: '5px'
                      }}
                    />
                  </div>
                  <span>{calculateProgress(goal).toFixed(0)}%</span>
                </div>
              </td>
              <td>
                <select 
                  value={goal.priority} 
                  onChange={(e) => handlePriorityChange(e, index)} 
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </td>
              <td
                className="editable-cell"
                onClick={() => setIsEditing(index)}
                title="Click to edit"
              >
                {isEditing === index ? (
                  <input
                    type="number"
                    value={goal.monthlyContribution}
                    onChange={(e) => handleContributionChange(e, index)}
                    onBlur={() => setIsEditing(null)}
                    autoFocus
                  />
                ) : (
                  <>
                    <span>₹{goal.monthlyContribution}</span>
                    <i className="edit-icon" />
                  </>
                )}
              </td>
              <td>
                <span 
                  className="pause-icon" 
                  title={goal.paused ? "Resume" : "Pause"} 
                  onClick={() => handlePauseGoal(index)} 
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                >
                  {goal.paused ? '▶️' : '⏸️'}
                </span>
                <span 
                  className="delete-icon" 
                  title="Abandon" 
                  onClick={() => handleDeleteGoal(index)} 
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                >
                  ❌
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Button to save details and calculate total contributions */}
      <div className="save-details">
        <button onClick={handleSaveDetails}>Save Details</button>
      </div>
      {/* Dropdown for Completed Goals */}
      <div className="goals-dropdown-section completed-goals">
        <div className="goals-dropdown-header" onClick={() => toggleDropdown('completed')}>
          <h3 className="goals-dropdown-title">
            Completed Goals
            <span className={`dropdown-arrow ${openDropdown === 'completed' ? 'open' : ''}`}>
              {openDropdown === 'completed' ? '▲' : '▼'}
            </span>
          </h3>
        </div>
        <div className={`goals-dropdown-content ${openDropdown === 'completed' ? 'open' : ''}`}>
          {completedGoals.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Goal Name</th>
                  <th>Amount to Save</th>
                  <th>Timeframe (months)</th>
                  <th>Current Savings</th>
                </tr>
              </thead>
              <tbody>
                {completedGoals.map((goal, index) => (
                  <tr key={index}>
                    <td>{goal.name}</td>
                    <td>₹{goal.amount}</td>
                    <td>{goal.timeframe}</td>
                    <td>₹{goal.currentSavings}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="goals-empty-state">No completed goals yet</div>
          )}
        </div>
      </div>

      {/* Dropdown for Paused Goals */}
      <div className="goals-dropdown-section paused-goals">
        <div className="goals-dropdown-header" onClick={() => toggleDropdown('paused')}>
          <h3 className="goals-dropdown-title">
            Paused Goals
            <span className={`dropdown-arrow ${openDropdown === 'paused' ? 'open' : ''}`}>
              {openDropdown === 'paused' ? '▲' : '▼'}
            </span>
          </h3>
        </div>
        <div className={`goals-dropdown-content ${openDropdown === 'paused' ? 'open' : ''}`}>
          {pausedGoals.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Goal Name</th>
                  <th>Amount to Save</th>
                  <th>Timeframe (months)</th>
                  <th>Current Savings</th>
                  <th>Monthly Contribution</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {pausedGoals.map((goal, index) => (
                  <tr key={index}>
                    <td>{goal.name}</td>
                    <td>₹{goal.amount}</td>
                    <td>{goal.timeframe}</td>
                    <td>₹{goal.currentSavings}</td>
                    <td>₹{goal.monthlyContribution}</td>
                    <td>
                      <span 
                        className="resume-icon" 
                        title="Resume" 
                        onClick={() => handleResumeGoal(index)} 
                        style={{ cursor: 'pointer' }}
                      >
                        ▶️
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="goals-empty-state">No paused goals</div>
          )}
        </div>
      </div>

      {/* Dropdown for Abandoned Goals */}
      <div className="goals-dropdown-section abandoned-goals">
        <div className="goals-dropdown-header" onClick={() => toggleDropdown('abandoned')}>
          <h3 className="goals-dropdown-title">
            Abandoned Goals
            <span className={`dropdown-arrow ${openDropdown === 'abandoned' ? 'open' : ''}`}>
              {openDropdown === 'abandoned' ? '▲' : '▼'}
            </span>
          </h3>
        </div>
        <div className={`goals-dropdown-content ${openDropdown === 'abandoned' ? 'open' : ''}`}>
          {abandonedGoals.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Goal Name</th>
                  <th>Amount to Save</th>
                  <th>Timeframe (months)</th>
                  <th>Current Savings</th>
                </tr>
              </thead>
              <tbody>
                {abandonedGoals.map((goal, index) => (
                  <tr key={index}>
                    <td>{goal.name}</td>
                    <td>₹{goal.amount}</td>
                    <td>{goal.timeframe}</td>
                    <td>₹{goal.currentSavings}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="goals-empty-state">No abandoned goals</div>
          )}
        </div>
      </div>

      
    </div>
  );
};

// Function to get the background color based on priority
const getPriorityColor = (priority) => {
  switch (priority) {
    case 'High':
      return 'red'; // Red for high priority
    case 'Medium':
      return 'yellow'; // Yellow for medium priority
    case 'Low':
      return 'green'; // Green for low priority
    default:
      return 'white'; // Default color
  }
};

export default ShortTermSavings;