import React, { useState, useEffect } from 'react';
import '../css/Retirement.css';
import { FaBalanceScale, FaLeaf, FaGem } from 'react-icons/fa';

function Retirement({onBudgetDataChange}) {
  const [categoryTotals, setCategoryTotals] = useState({
    Essentials: 0,
    Discretionary: 0,
    Debts: 0,
    'Short-term Savings': 0,
    'Long-term Savings': 0,
    'Emergency Fund': 0,
    Insurance: 0,
    'Retirement Fund': 0,
    Others: 0
  });
  const [selectedLifestyle, setSelectedLifestyle] = useState('current');
  const [customExpense, setCustomExpense] = useState('');
  const [showSavingsPlan, setShowSavingsPlan] = useState(false);
  const [currentSavings, setCurrentSavings] = useState('');
  const [hasSavings, setHasSavings] = useState(null);
  const [tempSavings, setTempSavings] = useState('');
  const [confirmedSavings, setConfirmedSavings] = useState(0);
  const [isSavingsConfirmed, setIsSavingsConfirmed] = useState(false);
  const [showStep2, setShowStep2] = useState(false);
  const [selectedSavingsOption, setSelectedSavingsOption] = useState(null); // 'fixed' or 'step'
  const [budgetSummary, setBudgetSummary] = useState({
    retirement: 0
  });
  const [editableMonthlyIncome, setEditableMonthlyIncome] = useState('');
  const [inflationRate, setInflationRate] = useState(0.06); // 7%
  const [timeToRetirement, setTimeToRetirement] = useState(30);
  const [retirementPeriod, setRetirementPeriod] = useState(30);
  const [expectedReturnAtRetirement, setExpectedReturnAtRetirement] = useState(0.08);
  const [realROIAtRetirement, setRealROIAtRetirement] = useState(0);
  const [futureValueOfAnnualExpense, setFutureValueOfAnnualExpense] = useState(0);
  const [retirementCorpus, setRetirementCorpus] = useState(0);
  const [expectedReturn, setExpectedReturn] = useState(0.15);
  const [realROI, setRealROI] = useState(0);
  const [currentRetirementSavings, setCurrentRetirementSavings] = useState(0);
  const [monthlySavingRequired, setMonthlySavingRequired] = useState(0);
  const [monthlySavingRequired_Step, setMonthlySavingRequired_Step] = useState(0);
  const [annualIncrement, setAnnualIncrement] = useState(0.05);
  const [isCalculatorExpanded, setIsCalculatorExpanded] = useState(false);

  useEffect(() => {
    console.log('Checking localStorage content');
    const savedTotals = localStorage.getItem('categoryTotals');
    console.log('Saved totals from localStorage:', savedTotals);
    
    if (savedTotals) {
      const totals = JSON.parse(savedTotals);
      console.log('Parsed totals:', totals);
      console.log('Essentials value:', totals.Essentials);
      console.log('Discretionary value:', totals.Discretionary);
      
      setCategoryTotals(totals);
    } else {
      console.log('No saved totals found in localStorage');
    }
  }, []);

  // Calculate monthly expenses
  const monthlyExpensesTotal = categoryTotals.Essentials + categoryTotals.Discretionary;

  // First, let's create a function to get the current monthly expense based on selected lifestyle
  const getCurrentMonthlyExpense = () => {
    switch(selectedLifestyle) {
      case 'minimalist':
        return monthlyExpensesTotal * 0.7;
      case 'luxury':
        return monthlyExpensesTotal * 1.3;
      case 'custom':
        return Number(customExpense) || 0;
      default: // 'current'
        return monthlyExpensesTotal;
    }
  };

  // Add this useEffect to handle all calculations
  useEffect(() => {
    // Calculate all values whenever any input changes
    const calculateAllValues = () => {
      // Calculate Real ROI values
      const calculatedRealROIAtRetirement = expectedReturnAtRetirement - inflationRate;
      setRealROIAtRetirement(calculatedRealROIAtRetirement);
      
      const calculatedRealROI = expectedReturn - inflationRate;
      setRealROI(calculatedRealROI);

      // Calculate Monthly and Annual Expenses
      const monthlyExpenseAtRetirement = editableMonthlyIncome;
      const annualExpenseAtRetirement = monthlyExpenseAtRetirement * 12;
      
      // Calculate Future Value
      const futureValueOfAnnualExpense = annualExpenseAtRetirement * 
        Math.pow((1 + inflationRate), timeToRetirement);
      setFutureValueOfAnnualExpense(futureValueOfAnnualExpense);

      // Calculate Retirement Corpus
      const calculatedRetirementCorpus = calculatedRealROIAtRetirement === 0
        ? futureValueOfAnnualExpense * retirementPeriod
        : futureValueOfAnnualExpense * 
          (1 - Math.pow((1 + calculatedRealROIAtRetirement), -retirementPeriod)) / 
          calculatedRealROIAtRetirement;
      
      setRetirementCorpus(calculatedRetirementCorpus);

      // Calculate Monthly Savings Required
      const monthlyRealROI = calculatedRealROI / 12;
      const totalMonths = timeToRetirement * 12;

      const calculatedMonthlySavingRequired = monthlyRealROI === 0
        ? (calculatedRetirementCorpus - currentRetirementSavings) / totalMonths
        : (
            calculatedRetirementCorpus - 
            (currentRetirementSavings * Math.pow(1 + monthlyRealROI, totalMonths))
          ) / (
            (Math.pow(1 + monthlyRealROI, totalMonths) - 1) / monthlyRealROI
          );
      
      setMonthlySavingRequired(calculatedMonthlySavingRequired);

      // Calculate Step-up Monthly Savings
      let annualSavingRequired_Step;
      if (calculatedRealROI !== annualIncrement) {
        const stepCalculatorNumerator = calculatedRetirementCorpus - 
          (currentRetirementSavings * Math.pow(1 + calculatedRealROI, timeToRetirement));

        const stepCalculatorDenominator = 
          (Math.pow(1 + calculatedRealROI, timeToRetirement) - Math.pow(1 + annualIncrement, timeToRetirement)) / 
          (calculatedRealROI - annualIncrement);

        annualSavingRequired_Step = stepCalculatorNumerator / stepCalculatorDenominator;
      } else {
        const stepCalculatorNumerator = calculatedRetirementCorpus - 
          (currentRetirementSavings * Math.pow(1 + calculatedRealROI, timeToRetirement));

        const stepCalculatorDenominator = timeToRetirement * Math.pow(1 + calculatedRealROI, timeToRetirement);

        annualSavingRequired_Step = stepCalculatorNumerator / stepCalculatorDenominator;
      }

      setMonthlySavingRequired_Step(annualSavingRequired_Step / 12);

      // Update calculated values for display
      setCalculatedValues({
        monthlyExpense: monthlyExpenseAtRetirement,
        annualExpense: annualExpenseAtRetirement,
        requiredCorpus: calculatedRetirementCorpus,
        monthlySavingRequired: calculatedMonthlySavingRequired,
        monthlySavingRequired_Step: annualSavingRequired_Step / 12
      });
    };

    calculateAllValues();
  }, [
    editableMonthlyIncome,
    inflationRate,
    timeToRetirement,
    retirementPeriod,
    expectedReturnAtRetirement,
    expectedReturn,
    currentRetirementSavings,
    annualIncrement
  ]);

  // Add state for calculated values
  const [calculatedValues, setCalculatedValues] = useState({
    monthlyExpense: 0,
    annualExpense: 0,
    requiredCorpus: 0,
    monthlySavingRequired: 0,
    monthlySavingRequired_Step: 0
  });

  // Add debug logs to verify calculations
  useEffect(() => {
    const savedCalculations = localStorage.getItem('retirementCalculations');
    if (savedCalculations) {
      console.log('Stored Retirement Calculations:', JSON.parse(savedCalculations));
      console.log('Step Calculator Results:', JSON.parse(savedCalculations).stepCalculatorResults);
    }
  }, []);

  // Update useEffect to set initial value
  useEffect(() => {
    if (calculatedValues.monthlyExpense) {
      setEditableMonthlyIncome(calculatedValues.monthlyExpense);
    }
  }, [calculatedValues.monthlyExpense]);

  // Add this useEffect to update editableMonthlyIncome when lifestyle changes
  useEffect(() => {
    const getCurrentMonthlyExpense = () => {
      const baseExpense = monthlyExpensesTotal; // This comes from categoryTotals
      switch(selectedLifestyle) {
        case 'minimalist':
          return baseExpense * 0.7;
        case 'luxury':
          return baseExpense * 1.3;
        case 'custom':
          return Number(customExpense) || baseExpense;
        default: // 'current'
          return baseExpense;
      }
    };

    const lifestyleBasedExpense = getCurrentMonthlyExpense();
    setEditableMonthlyIncome(lifestyleBasedExpense);
    
    console.log('Lifestyle changed to:', selectedLifestyle);
    console.log('New monthly expense:', lifestyleBasedExpense);
  }, [selectedLifestyle, monthlyExpensesTotal, customExpense]);

  const renderBasicInputs = () => (
    <div className="retirement-table">
      {/* Monthly Income Section */}
      <div className="calculator-section">
        <h3>Monthly Income Details</h3>
        <div className="retirement-input-row">
          <label>Monthly income at retirement based on {selectedLifestyle} lifestyle (Today's equivalent)</label>
          <input 
            type="number"
            value={Math.round(editableMonthlyIncome)}
            onChange={(e) => {
              const value = Number(e.target.value);
              setEditableMonthlyIncome(value);
            }}
            placeholder="Enter amount"
          />
          {/* <span className="lifestyle-note">
            {selectedLifestyle !== 'custom' 
              ? `Based on ${selectedLifestyle} lifestyle` 
              : 'Custom amount'}
          </span> */}
        </div>
        <div className="retirement-input-row">
          <label>Inflation rate (%)</label>
          <input 
            type="number"
            value={(inflationRate * 100).toFixed(1)}
            onChange={(e) => setInflationRate(Number(e.target.value) / 100)}
            placeholder="Enter rate"
          />
        </div>
        <div className="retirement-input-row">
          <label>Time to retirement (Years)</label>
          <input 
            type="number"
            value={timeToRetirement}
            onChange={(e) => setTimeToRetirement(Number(e.target.value))}
            placeholder="Enter years"
          />
        </div>
      </div>

      {/* Retirement Corpus Section */}
      <div className="calculator-section">
        <h3>Retirement Corpus Details</h3>
        <div className="retirement-input-row">
          <label>Retirement Period (Years)</label>
          <input 
            type="number"
            value={retirementPeriod}
            onChange={(e) => setRetirementPeriod(Number(e.target.value))}
            placeholder="Enter years"
          />
        </div>
        <div className="retirement-input-row">
          <label>Expected Return (After retirement) (%)</label>
          <input 
            type="number"
            value={(expectedReturnAtRetirement * 100).toFixed(1)}
            onChange={(e) => setExpectedReturnAtRetirement(Number(e.target.value) / 100)}
            placeholder="Enter rate"
          />
        </div>
        <div className="retirement-input-row">
          <label>Real ROI after retirement (%)</label>
          <input 
            type="number"
            value={(realROIAtRetirement * 100).toFixed(1)}
            disabled
            placeholder="Calculated value"
          />
        </div>
        <div className="retirement-input-row">
          <label>Retirement Corpus</label>
          <input 
            type="number"
            value={Math.round(retirementCorpus)}
            disabled
            placeholder="Calculated value"
          />
        </div>
      </div>

      {/* Monthly Installments Section */}
      <div className="calculator-section">
        <h3>Monthly Installments Details</h3>
        <div className="retirement-input-row">
          <label>Expected Return (%)</label>
          <input 
            type="number"
            value={(expectedReturn * 100).toFixed(1)}
            onChange={(e) => setExpectedReturn(Number(e.target.value) / 100)}
            placeholder="Enter rate"
          />
        </div>
        <div className="retirement-input-row">
          <label>Real ROI (during accumulation) (%)</label>
          <input 
            type="number"
            value={(realROI * 100).toFixed(1)}
            disabled
            placeholder="Calculated value"
          />
        </div>
        <div className="retirement-input-row">
          <label>Years to retirement</label>
          <input 
            type="number"
            value={timeToRetirement}
            onChange={(e) => setTimeToRetirement(Number(e.target.value))}
            placeholder="Enter years"
          />
        </div>
        <div className="retirement-input-row">
          <label>Beginning Balance (Current Savings)</label>
          <input 
            type="number"
            value={Math.round(currentRetirementSavings)}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              setCurrentRetirementSavings(newValue);
              setTempSavings(String(newValue));
              setCurrentSavings(newValue);
              setConfirmedSavings(newValue);
            }}
            placeholder="Enter amount"
          />
        </div>
        <div className="retirement-input-row">
          <label>Savings Monthly</label>
          <input 
            type="number"
            value={Math.round(monthlySavingRequired)}
            disabled
            placeholder="Calculated value"
          />
        </div>
        <div className="retirement-input-row">
          <label>Step Savings Monthly</label>
          <input 
            type="number"
            value={Math.round(monthlySavingRequired_Step)}
            disabled
            placeholder="Calculated value"
          />
        </div>
        <div className="retirement-input-row">
          <label>Annual Increment to Step Savings (%)</label>
          <input 
            type="number"
            value={(annualIncrement * 100).toFixed(1)}
            onChange={(e) => setAnnualIncrement(Number(e.target.value) / 100)}
            placeholder="Enter rate"
          />
        </div>
      </div>
    </div>
  );

  // Add handler for confirming savings
  const handleConfirmSavings = () => {
    const newSavings = Number(tempSavings) || 0;
    setCurrentSavings(newSavings);
    setCurrentRetirementSavings(newSavings);  // This will trigger recalculation
    setConfirmedSavings(newSavings);
    setShowStep2(true);
  };

  const updateBudgetSummary = (selectedAmount) => {
    // Update the retirement value in the budget summary
    setBudgetSummary(prevBudget => ({
      ...prevBudget,
      retirement: selectedAmount
    }));

    // Log for verification
    console.log('Updated retirement savings in budget:', selectedAmount);
  };

  return (
    <div className="retirement-page-container">
      <div className="retirement-content-container">
        <div className="retirement-description">
          <div className="retirement-description-header">
            <h2>Retirement Planning Calculator</h2>
            <p className="retirement-description-subtitle">Plan your future with confidence</p>
          </div>
          <div className="retirement-description-features">
            <div className="retirement-feature-item">
              <span className="retirement-feature-icon">🎯</span>
              <span>Set retirement goals</span>
            </div>
            <div className="retirement-feature-item">
              <span className="retirement-feature-icon">💰</span>
              <span>Calculate corpus needed</span>
            </div>
            <div className="retirement-feature-item">
              <span className="retirement-feature-icon">📊</span>
              <span>Flexible lifestyle options</span>
            </div>
            <div className="retirement-feature-item">
              <span className="retirement-feature-icon">📈</span>
              <span>Smart savings plans</span>
            </div>
          </div>
        </div>

        <div className="retirement-header-section">
          <h1 className="retirement-title">Plan your retirement fund</h1>
          <p className="retirement-description">
            We've analyzed your current spending habits to help you plan for a stress-free retirement. 
            Let's choose the lifestyle you want to maintain!
          </p>

          <div className="lifestyle-section">
            <h2 className="lifestyle-title">Choose Your Retirement Lifestyle</h2>
            <div className="lifestyle-options">
              <div 
                className={`lifestyle-tile ${selectedLifestyle === 'minimalist' ? 'selected' : ''}`}
                onClick={() => setSelectedLifestyle('minimalist')}
              >
                <input 
                  type="radio" 
                  name="lifestyle" 
                  checked={selectedLifestyle === 'minimalist'}
                  onChange={() => setSelectedLifestyle('minimalist')}
                />
                <FaLeaf className="lifestyle-icon" />
                <h3>Minimalist Lifestyle</h3>
                <p className="lifestyle-amount">₹{(monthlyExpensesTotal * 0.7).toLocaleString()}</p>
                <p className="lifestyle-calculation">(70% of current monthly expense)</p>
                <p className="lifestyle-description">Reduce discretionary spending for a simpler lifestyle.</p>
              </div>

              <div 
                className={`lifestyle-tile ${selectedLifestyle === 'current' ? 'selected' : ''}`}
                onClick={() => setSelectedLifestyle('current')}
              >
                <input 
                  type="radio" 
                  name="lifestyle" 
                  checked={selectedLifestyle === 'current'}
                  onChange={() => setSelectedLifestyle('current')}
                />
                <FaBalanceScale className="lifestyle-icon" />
                <h3>Current Lifestyle</h3>
                <p className="lifestyle-amount">₹{monthlyExpensesTotal.toLocaleString()}</p>
                <p className="lifestyle-calculation">(Current monthly expense)</p>
                <p className="lifestyle-description">Maintain your current standard of living.</p>
              </div>

              <div  
                className={`lifestyle-tile ${selectedLifestyle === 'luxury' ? 'selected' : ''}`}
                onClick={() => setSelectedLifestyle('luxury')}
              >
                <input 
                  type="radio" 
                  name="lifestyle" 
                  checked={selectedLifestyle === 'luxury'}
                  onChange={() => setSelectedLifestyle('luxury')}
                />
                <FaGem className="lifestyle-icon" />
                <h3>Luxury Lifestyle</h3>
                <p className="lifestyle-amount">₹{(monthlyExpensesTotal * 1.3).toLocaleString()}</p>
                <p className="lifestyle-calculation">(130% of current monthly expense)</p>
                <p className="lifestyle-description">Plan for a more indulgent retirement.</p>
              </div>
            </div>

            <div className="custom-expense-section">
              <p className="custom-expense-text">
                Want to choose a different monthly expense? Enter here: 
                <input 
                  type="number"
                  className="custom-expense-input"
                  placeholder="Enter amount"
                  value={customExpense}
                  onChange={(e) => {
                    setCustomExpense(e.target.value);
                    setSelectedLifestyle('custom');
                  }}
                />
              </p>
            </div>

            {/* Add results display */}
            <div className="retirement-calculations">
              <div className="calculation-item">
                <label>Monthly Expenses:</label>
                <span>₹{Math.round(calculatedValues.monthlyExpense).toLocaleString()}</span>
              </div>
              <div className="calculation-item">
                <label>Annual Expenses:</label>
                <span>₹{Math.round(calculatedValues.annualExpense).toLocaleString()}</span>
              </div>
              <div className="calculation-item">
                <label>Required Retirement Corpus:</label>
                <span>₹{Math.round(calculatedValues.requiredCorpus).toLocaleString()}</span>
              </div>
              
              <div className="retirement-context">
                <p className="reassuring-text">
                  This is the amount you'll need to comfortably maintain your{' '}
                  <span className="highlight">
                    {selectedLifestyle === 'custom' ? 'chosen' : selectedLifestyle}{' '}
                    lifestyle
                  </span>{' '}
                  for{' '}
                  <span className="highlight">30 years</span>.
                </p>
                <button 
                  className="plan-savings-btn" 
                  onClick={() => {
                    setShowSavingsPlan(true);
                  }}
                >
                  Plan My Savings
                </button>
              </div>
            </div>
          </div>

          {/* Savings Plan Section */}
          {showSavingsPlan && (
            <div className="savings-plan-section">
              <h2 className="savings-plan-title">Your Retirement Savings Plan</h2>
              <div className="savings-step">
                <h3 className="step-title">Step 1: Current Savings</h3>
                <p className="step-question">Do you already have savings for retirement?</p>
                
                <div className="savings-options">
                  <button 
                    className={`savings-option-btn ${hasSavings === true ? 'active' : ''}`}
                    onClick={() => {
                      setHasSavings(true);
                      setCurrentSavings(0);  // Reset current savings when switching to Yes
                    }}
                  >
                    Yes
                  </button>
                  <button 
                    className={`savings-option-btn ${hasSavings === false ? 'active' : ''}`}
                    onClick={() => {
                      setHasSavings(false);
                      setCurrentSavings(0);
                      setCurrentRetirementSavings(0);
                      setTempSavings('');
                      setConfirmedSavings(0);
                      setShowStep2(true);
                    }}
                  >
                    No
                  </button>
                </div>

                {hasSavings === true && (
                  <div className="current-savings-input">
                    <label>Enter your current retirement savings:</label>
                    <div className="input-with-currency">
                      <span className="currency-symbol">₹</span>
                      <input
                        type="number"
                        value={tempSavings}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setTempSavings(newValue);
                          setCurrentRetirementSavings(Number(newValue) || 0);
                        }}
                        placeholder="Enter amount"
                      />
                      <button 
                        className="confirm-savings-btn"
                        onClick={() => {
                          const newSavings = Number(tempSavings) || 0;
                          setCurrentSavings(newSavings);
                          setCurrentRetirementSavings(newSavings);
                          setConfirmedSavings(newSavings);
                          setShowStep2(true);
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                )}

                {hasSavings === false && (
                  <p className="no-savings-message">
                    No worries! Let's start building your plan today.
                  </p>
                )}

                {showStep2 && (
                  <div className="savings-step">
                    <h3 className="step-title">Step 2: Choose your savings approach</h3>
                    
                    <div className="savings-options">
                      <div 
                        className={`savings-option ${selectedSavingsOption === 'fixed' ? 'selected' : ''}`}
                        onClick={() => setSelectedSavingsOption('fixed')}
                      >
                        <h4>Option 1: Fixed Savings</h4>
                        <div className="savings-amount-display">
                          <span className="amount-label">Required Monthly Savings</span>
                          <span className="amount-value">
                            ₹{Math.round(calculatedValues.monthlySavingRequired).toLocaleString()}
                          </span>
                        </div>
                      </div>

                      <div 
                        className={`savings-option ${selectedSavingsOption === 'step' ? 'selected' : ''}`}
                        onClick={() => setSelectedSavingsOption('step')}
                      >
                        <h4>Option 2: Step-Up Savings</h4>
                        <div className="savings-amount-display">
                          <span className="amount-label">Required Monthly Savings</span>
                          <span className="amount-value">
                            ₹{Math.round(calculatedValues.monthlySavingRequired_Step).toLocaleString()}
                          </span>
                          <span className="increment-note">
                            incremented by {(annualIncrement * 100).toFixed(1)}% annually
                          </span>
                        </div>
                      </div>
                    </div>

                    <p className="savings-note"><i>
                      If the fixed savings feel too high, consider starting smaller and increasing annually. 
                      Both options will help you reach your goal!
                    </i></p>

                    {selectedSavingsOption && (
                      <button 
                        className="save-details-btn"
                        onClick={() => {
                          const selectedAmount = selectedSavingsOption === 'fixed' 
                            ? calculatedValues.monthlySavingRequired 
                            : calculatedValues.monthlySavingRequired_Step;
                          
                          // Update budget summary
                          updateBudgetSummary(selectedAmount);
                          onBudgetDataChange('Retirement Fund', selectedAmount); // Pass 0 to reset the value

                          // Optional: Show success message
                          }}
                      >
                        Save Details
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Tabs Container */}
          <div className="retirement-calculator-section">
            <div className="calculator-explanation">
              <p className="explanation-question">
                Want to see how we calculated your retirement corpus?
              </p>
              <p className="explanation-instruction">
                Click below to view the detailed calculations and adjust the parameters to create a more customized retirement plan.
              </p>
            </div>

            <div className="retirement-calculator-container">
              <button 
                className={`calculator-tab ${isCalculatorExpanded ? 'expanded' : ''}`}
                onClick={() => setIsCalculatorExpanded(!isCalculatorExpanded)}
              >
                <span>Retirement Calculator</span>
                <span className="expand-icon">{isCalculatorExpanded ? '−' : '+'}</span>
              </button>

              {isCalculatorExpanded && (
                <div className="calculator-content">
                  {renderBasicInputs()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Retirement;
