import React, { useState, useEffect } from 'react';
import FundPlan from './FundPlan'; // Import the FundPlan component
import BudgetSummary from './BudgetSummary'; // Import the BudgetSummary component
import '../css/EmergencyFund.css';

function EmergencyFund({ onBudgetDataChange, onSave }) { // Accept onBudgetDataChange as a prop
  const [categories, setCategories] = useState(['']);
  const [amounts, setAmounts] = useState(['']);
  const [salary, setSalary] = useState('');
  const [existingFund, setExistingFund] = useState(0); // Default value set to 0
  const [error, setError] = useState('');
  const [isCalculated, setIsCalculated] = useState(false);
  const [selectedFund, setSelectedFund] = useState(null);
  const [congratulationsMessage, setCongratulationsMessage] = useState(false); // To track if the user has met all goals
  const [showFundPlan, setShowFundPlan] = useState(false); // State to control FundPlan visibility
  const [selectedOption, setSelectedOption] = useState(null); // State to hold selected option for FundPlan

  useEffect(() => {
    const totalSpend = localStorage.getItem('totalSpend');
    if (totalSpend) {
      setCategories(['MonthlySpends']);
      setAmounts([totalSpend]);
    }
  }, []);

  useEffect(() => {
    const savedIncome = localStorage.getItem('monthlyIncome');
    if (savedIncome) {
      setSalary(savedIncome);
    }
  }, []);

  const handleCategoryChange = (index, value) => {
    const newCategories = [...categories];
    newCategories[index] = value;
    setCategories(newCategories);
    validateCategories(newCategories);
  };

  const handleAmountChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = value;
    setAmounts(newAmounts);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  const handleExistingFundChange = (e) => {
    setExistingFund(parseFloat(e.target.value) || 0); // Update existing emergency fund
  };

  const handleAddCategory = () => {
    if (categories.length < 10) {
      const newCategories = [...categories, ''];
      const newAmounts = [...amounts, ''];
      setCategories(newCategories);
      setAmounts(newAmounts);
    }
  };

  const validateCategories = (categoriesList) => {
    const uniqueCategories = new Set(categoriesList);
    if (uniqueCategories.size !== categoriesList.length) {
      setError('Categories must be unique');
    } else {
      setError('');
    }
  };

  const handleCalculateClick = () => {
    if (salary === '' || categories.some(cat => cat === '') || amounts.some(amount => amount === '')) {
      setError('Please fill out all fields');
    } else {
      setIsCalculated(true);
      setError('');
    }
  };

  const totalExpenses = amounts.reduce((acc, val) => acc + parseFloat(val) || 0, 0);
  const threeTimesIncome = Math.max(3 * salary - existingFund, 0); // Consider existing fund
  const sixTimesIncome = Math.max(6 * salary - existingFund, 0); // Consider existing fund
  const threeTimesExpenses = Math.max(3 * totalExpenses - existingFund, 0); // Consider existing fund
  const sixTimesExpenses = Math.max(6 * totalExpenses - existingFund, 0); // Consider existing fund

  let emergencyFundOptions = [
    { title: '3x Income', totalValue: 3 * salary, value: threeTimesIncome, description: 'Three months of your monthly income.' },
    { title: '3x Expenses', totalValue: 3 * totalExpenses, value: threeTimesExpenses, description: 'Three months of your total expenses.' },
    { title: '6x Income', totalValue: 6 * salary, value: sixTimesIncome, description: 'Six months of your monthly income.' },
    { title: '6x Expenses', totalValue: 6 * totalExpenses, value: sixTimesExpenses, description: 'Six months of your total expenses.' }
  ];

  emergencyFundOptions.sort((a, b) => a.totalValue - b.totalValue); // Sort options based on totalValue

  // Find the highest goal
  const highestGoal = emergencyFundOptions[emergencyFundOptions.length - 1].totalValue;

  useEffect(() => {
    if (existingFund >= highestGoal) {
      setCongratulationsMessage(true); // Set the congratulatory message if the highest goal is met or exceeded
      setSelectedFund(null); // No need to select a fund
    } else {
      setCongratulationsMessage(false); // Reset the congratulatory message
      setSelectedFund(0); // Default to the first option in the list
    }
  }, [isCalculated, highestGoal]);

  const handleFundSelection = (index) => {
    if (index !== selectedFund) {
        setSelectedFund(index);
        setShowFundPlan(false); // Collapse the FundPlan
        // Optionally, you can reset the existing fund or any other state if needed
        setExistingFund(existingFund); // Keep the existing fund as is
    }
  };

  const handleSaveDetails = () => {
    const selectedOption = {
      title: emergencyFundOptions[selectedFund].title,
      value: emergencyFundOptions[selectedFund].value,
      totalValue: emergencyFundOptions[selectedFund].totalValue
    };
    
    // Reset the 'Emergency Fund' value in budget data to 0
    onBudgetDataChange('Emergency Fund', 0); // Pass 0 to reset the value

    // Set the selected option for FundPlan
    setSelectedOption(selectedOption); // Set the selected option for FundPlan
    setShowFundPlan(true); // Show the FundPlan component
  };
  
  useEffect(() => {
    const emergencyFundPage = document.querySelector('.emergency-fund-page');
    const emContentContainer = document.querySelector('.em-content-container');
    
    console.log('Emergency Fund Component Widths:');
    console.log('- emergency-fund-page:', emergencyFundPage?.offsetWidth);
    console.log('- em-content-container:', emContentContainer?.offsetWidth);
  }, []); // Run once on mount

  return (
    <div className="em-page-container">
      <div className="emergency-description">
        <div className="emergency-description-header">
          <h2>Emergency Fund Calculator</h2>
          <p className="emergency-description-subtitle">Build your financial safety net</p>
        </div>
        <div className="emergency-description-features">
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">🛡️</span>
            <span>Optimal Fund</span>
          </div>
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">📊</span>
            <span>Multiple options</span>
          </div>
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">💰</span>
            <span>Track your progress</span>
          </div>
          <div className="emergency-feature-item">
            <span className="emergency-feature-icon">📈</span>
            <span>Personalized plan</span>
          </div>
        </div>
      </div>

      <div className="em-content-container">
        <div className="existing-fund-section">
          <h2 className="section-description">
            To calculate how much you need in your emergency fund, please enter your current emergency fund amount.
          </h2>
          <div className="input-group">
            <label>Current Emergency Fund Amount:</label>
            <input
              type="number"
              value={existingFund}
              onChange={(e) => setExistingFund(e.target.value)}
              placeholder="Enter amount"
            />
          </div>
        </div>

        <div className="emergency-form">
          <form className="emergency-fund-form" onSubmit={(e) => e.preventDefault()}> {/* Prevent default form submission */}
            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td>
                      <select
                        value={category}
                        onChange={(e) => handleCategoryChange(index, e.target.value)}
                        className="input-select"
                      >
                        <option value="">Select a category</option>
                        <option value="MonthlySpends">MonthlySpends</option>
                        <option value="Rent/Mortgage">Rent/Mortgage</option>
                        <option value="Utilities">Utilities</option>
                        <option value="Groceries">Groceries</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Others">Others</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="number"
                        value={amounts[index]}
                        onChange={(e) => handleAmountChange(index, e.target.value)}
                        min="0"
                        className="input-number"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button type="button" onClick={handleAddCategory} className="add-category-button">
              Add More
            </button>
            <div className="salary-input-section">
              <label className="salary-label">
                Monthly Income:
              </label>
              <input
                type="number"
                className="salary-input"
                value={salary}
                onChange={handleSalaryChange}
                placeholder="Enter your monthly income"
              />
            </div>
            <button type="button" onClick={handleCalculateClick} className="calculate-button">
              Calculate Emergency Fund
            </button>
          </form>
          
          {error && <p className="error-message">{error}</p>}
          {isCalculated && (
            <div className="calculation-results">
              <h3>Your Emergency Fund Options</h3>
              {congratulationsMessage && <p className="congratulations-message">Congratulations! You've met all emergency fund goals.</p>}
              <div className="options-section">
                {emergencyFundOptions.map((option, index) => (
                  <div
                    key={index}
                    className={`fund-card ${index === selectedFund ? 'selected' : ''}`}
                    onClick={() => handleFundSelection(index)}
                  >
                    <input
                      type="radio"
                      id={`option-${index}`}
                      name="emergency-fund"
                      checked={index === selectedFund}
                      onChange={() => handleFundSelection(index)}
                    />
                    <div className="option-content">
                      <p className="option-title">{option.title}</p>
                      <p className="option-value"><strong>₹{option.value.toLocaleString('en-IN')}</strong></p>
                      <p className="option-description">{option.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              {!congratulationsMessage && (
                <button type="button" onClick={handleSaveDetails} className="get-started-button">
                  Save Details
                </button>
              )}
            </div>
          )}
        </div>

        {/* Conditionally render the FundPlan component */}
        {showFundPlan && (
            <FundPlan 
                existingFund={existingFund} 
                selectedOption={selectedOption} 
                onBudgetDataChange={onBudgetDataChange} // Pass the function to FundPlan
                onSave={onSave} // Pass onSave to FundPlan
            />
        )}
      </div>
    </div>
  );
}

export default EmergencyFund;
