import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProgressBar from './ProgressBar';
import '../css/Priority.css';
import '../css/CommonLayout.css';
import arrowUpImage from '../../assets/arrowup.png';
import arrowDownImage from '../../assets/arrowdown.png';

const initialPriorityItems = [
  { 
    id: 'debtReduction', 
    content: 'Debt Reduction',
    description: 'Pay off your debts faster'
  },
  { 
    id: 'longTermSavings', 
    content: 'Long-term Savings',
    description: 'Save for major life events like retirement'
  },
  { 
    id: 'shortTermSavings', 
    content: 'Short-term Savings',
    description: 'Save for goals like vacations or car purchase'
  },
  { 
    id: 'insurance', 
    content: 'Insurance',
    description: 'Protect against health, life, and financial risks'
  },
  { 
    id: 'emergencyFund', 
    content: 'Emergency Fund',
    description: 'Build a fund for unexpected expenses'
  },
  { 
    id: 'reduceSpends', 
    content: 'Expense Reduction',
    description: 'Identify areas to reduce spending'
  },
  { 
    id: 'retirementFund', 
    content: 'Retirement Fund',
    description: 'Build a retirement corpus and plan your retirement'
  }
];

function Priority() {
  const [priorityItems, setPriorityItems] = useState(initialPriorityItems);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const storedPriorities = localStorage.getItem('financialPriorities');
      console.log('Stored priorities:', storedPriorities); // Debug log

      if (storedPriorities) {
        const parsedPriorities = JSON.parse(storedPriorities);
        console.log('Parsed priorities:', parsedPriorities); // Debug log
        
        // Ensure we always have 7 items
        if (parsedPriorities.length === 7 && parsedPriorities[0]?.description) {
          setPriorityItems(parsedPriorities);
        } else {
          console.log('Using initial priorities'); // Debug log
          setPriorityItems(initialPriorityItems);
          localStorage.setItem('financialPriorities', JSON.stringify(initialPriorityItems));
        }
      } else {
        console.log('No stored priorities, using initial'); // Debug log
        localStorage.setItem('financialPriorities', JSON.stringify(initialPriorityItems));
      }
    } catch (error) {
      console.error('Error loading priorities:', error);
      setPriorityItems(initialPriorityItems);
    }
  }, []);

  const moveItem = (index, direction) => {
    if ((direction === -1 && index > 0) || (direction === 1 && index < priorityItems.length - 1)) {
      const newItems = [...priorityItems];
      const item = newItems[index];
      newItems.splice(index, 1);
      newItems.splice(index + direction, 0, item);
      setPriorityItems(newItems);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      console.log('Saving priorities:', priorityItems); // Debug log
      localStorage.setItem('financialPriorities', JSON.stringify(priorityItems));
      localStorage.setItem('pyramidPriorities', JSON.stringify(priorityItems));
      navigate('/pyramid', { state: { priorities: priorityItems } });
    } catch (error) {
      console.error('Error saving priorities:', error);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(priorityItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPriorityItems(items);
  };

  if (!priorityItems || priorityItems.length !== 7) {
    console.log('Invalid priorities state:', priorityItems); // Debug log
    return null; // Or loading state
  }

  return (
    <div className="main-container">
      <div className="progress-bar-container">
        <ProgressBar currentStep={2} />
      </div>
      <div className="priority-page-container">
        <div className="priority-content-container">
          <div className="priority-header">
            <h2 className="priority-title">Financial Priority Ranking</h2>
            <div className="priority-instructions">
              <p className="priority-main-description">
                Understanding your financial priorities helps create a personalized plan that aligns with your goals.
              </p>
              <div className="priority-instruction-box">
                <h3 className="priority-instruction-title">How to Use</h3>
                <ol className="priority-instruction-list">
                  <li className="priority-instruction-item">
                    Review each <span className="priority-highlight">financial goal</span> and its description
                  </li>
                  <li className="priority-instruction-item">
                    <span className="priority-highlight">Drag and drop</span> or use <span className="priority-highlight">arrows</span> to reorder goals
                  </li>
                  <li className="priority-instruction-item">
                    Rank them from <span className="priority-highlight">most important (#1)</span> to <span className="priority-highlight">least important (#7)</span>
                  </li>
                  <li className="priority-instruction-item">
                    Click <span className="priority-highlight">Continue to Financial Plan</span> when you're satisfied with the order
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="priority-form-container">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="priorities">
                {(provided) => (
                  <ul 
                    className="priorities-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {priorityItems.map((item, index) => (
                      <Draggable 
                        key={item.id} 
                        draggableId={item.id} 
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`priority-item ${snapshot.isDragging ? 'dragging' : ''}`}
                          >
                            <span className="priority-rank">{index + 1}</span>
                            <div className="priority-text">
                              <div className="priority-content">{item.content}</div>
                              <div className="priority-description">{item.description}</div>
                            </div>
                            <div className="priority-buttons">
                              <button 
                                type="button" 
                                onClick={() => moveItem(index, -1)} 
                                disabled={index === 0}
                                title="Move Up"
                              >
                                <img src={arrowUpImage} alt="Move Up" />
                              </button>
                              <button 
                                type="button" 
                                onClick={() => moveItem(index, 1)} 
                                disabled={index === priorityItems.length - 1}
                                title="Move Down"
                              >
                                <img src={arrowDownImage} alt="Move Down" />
                              </button>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <button type="submit" className="priority-submit-button">
              Continue to Financial Plan
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Priority;
