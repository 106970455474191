import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/Conclusions.css';
import axios from 'axios';

const getTargetMonth = () => {
  const today = new Date();
  const currentDate = today.getDate();
  const currentMonth = today.getMonth();
  
  const targetMonth = currentDate > 8 ? currentMonth + 1 : currentMonth;
  const monthIndex = targetMonth > 11 ? 0 : targetMonth;
  
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  return monthNames[monthIndex];
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount).replace('₹', '₹ ');
};

const Conclusions = () => {
  const [summary, setSummary] = useState({ progress: [], improvements: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [milestones, setMilestones] = useState([
    {
      title: 'No Budget',
      description: 'Starting point of your financial journey.',
      status: 'completed'
    },
    {
      title: 'Budget Set!',
      description: "You've taken the first step by setting your budget.",
      status: 'completed'
    }
  ]);

  const location = useLocation();
  const targetMonth = getTargetMonth();
  
  // Get pyramid priorities from localStorage
  const storedPriorities = JSON.parse(localStorage.getItem('pyramidPriorities') || '[]');
  
  // Get selected goals from localStorage
  const savedGoalsData = JSON.parse(localStorage.getItem('selectedGoals') || '[]');
  
  console.log('Pyramid priorities:', storedPriorities);
  console.log('Saved goals:', savedGoalsData);
  
  // Get both budget data and spend breakdown with error handling
  let budgetSummary, spendBreakdown;
  try {
    budgetSummary = location.state?.budgetSummary || 
      JSON.parse(localStorage.getItem('budgetSummary') || '{}');
    spendBreakdown = location.state?.spendBreakdown || 
      JSON.parse(localStorage.getItem('spendBreakdown') || '{}');
    
    // Combine Discretionary, Essentials, and Others into Spends
    if (spendBreakdown.amounts) {
      const discretionaryAmount = spendBreakdown.amounts.find(
        item => item.category === 'Discretionary'
      )?.amount || 0;
      
      const essentialsAmount = spendBreakdown.amounts.find(
        item => item.category === 'Essentials'
      )?.amount || 0;

      const othersAmount = spendBreakdown.amounts.find(
        item => item.category === 'Others'
      )?.amount || 0;

      // Remove individual entries
      spendBreakdown.amounts = spendBreakdown.amounts.filter(
        item => !['Discretionary', 'Essentials', 'Others'].includes(item.category)
      );

      // Add combined Expenses (formerly Spends) to spendBreakdown
      spendBreakdown.amounts.push({
        category: 'Expenses',
        amount: discretionaryAmount + essentialsAmount + othersAmount
      });
    }
    
    console.log('Modified spend breakdown:', spendBreakdown);
    console.log('Stored pyramid priorities:', storedPriorities);
    console.log('Saved goals data:', savedGoalsData);
  } catch (error) {
    console.error('Error parsing data:', error);
    budgetSummary = {};
    spendBreakdown = {};
  }

  // Extract emergency fund and debt amounts
  const emergencyFundAmount = budgetSummary.expenses?.amounts?.find(
    item => item.category === 'Emergency Fund'
  )?.amount || 0;

  const debtAmount = budgetSummary.expenses?.amounts?.find(
    item => item.category === 'Debt Reduction' || item.category === 'Debts'
  )?.amount || 0;

  // Get all unique categories from both datasets
  const getAllCategories = () => {
    const spendCategories = spendBreakdown.amounts?.map(item => {
      // Map 'Spends' to 'Expenses'
      if (item.category === 'Spends') return 'Expenses';
      // Map 'Debts' to 'Debt Repayment'
      if (item.category === 'Debts') return 'Debt Repayment';
      return item.category;
    }) || [];

    const budgetCategories = budgetSummary.expenses?.amounts?.map(item => {
      // Map 'Spends' to 'Expenses'
      if (item.category === 'Spends') return 'Expenses';
      // Map 'Debts' to 'Debt Repayment'
      if (item.category === 'Debts') return 'Debt Repayment';
      return item.category;
    }) || [];
    
    // Combine and remove duplicates
    return [...new Set([...spendCategories, ...budgetCategories])];
  };

  // Get amount for a category from either dataset
  const getAmount = (category, source) => {
    if (source === 'budget') {
      // Find the amount directly in budgetSummary
      const amount = budgetSummary.expenses?.amounts?.find(
        item => item.category === category
      )?.amount || 0;

      console.log(`Getting budget amount for ${category}:`, amount);
      return amount;
    } else {
      // For spend breakdown data (original spending)
      if (category === 'Expenses') {
        const discretionary = spendBreakdown.amounts?.find(
          item => item.category === 'Discretionary'
        )?.amount || 0;
        
        const essentials = spendBreakdown.amounts?.find(
          item => item.category === 'Essentials'
        )?.amount || 0;

        const others = spendBreakdown.amounts?.find(
          item => item.category === 'Others'
        )?.amount || 0;
        
        return discretionary + essentials + others;
      }
      
      // For other categories in spend breakdown
      const amount = spendBreakdown.amounts?.find(
        item => item.category === category
      )?.amount || 0;

      console.log(`Getting spend amount for ${category}:`, amount);
      return amount;
    }
  };

  const uniqueCategories = getAllCategories();

  // Calculate changes in spending
  const calculateChange = (category, newAmount) => {
    let oldSpending = 0;

    if (category === 'Expenses') {
      // For Expenses category, combine Discretionary, Essentials, and Others
      const discretionary = spendBreakdown.amounts?.find(
        item => item.category === 'Discretionary'
      )?.amount || 0;
      
      const essentials = spendBreakdown.amounts?.find(
        item => item.category === 'Essentials'
      )?.amount || 0;

      const others = spendBreakdown.amounts?.find(
        item => item.category === 'Others'
      )?.amount || 0;
      
      oldSpending = discretionary + essentials + others;
    } else {
      // For other categories, find direct match
      oldSpending = spendBreakdown.amounts?.find(
        item => item.category === category
      )?.amount || 0;
    }
    
    const change = newAmount - oldSpending;
    const percentChange = oldSpending ? (change / oldSpending) * 100 : 0;
    
    return {
      amount: change,
      percentage: percentChange
    };
  };

  const fetchOpenAISummaryAndMilestones = async () => {
    try {
      setIsLoading(true);
      
      // Prepare the data for the prompt
      const budgetData = uniqueCategories.map(category => {
        const newAmount = getAmount(category, 'budget');
        const change = calculateChange(category, newAmount);
        return {
          category,
          currentAmount: formatCurrency(newAmount),
          change: formatCurrency(Math.abs(change.amount)),
          direction: change.amount > 0 ? 'increased' : 'decreased'
        };
      });

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4o",
          messages: [
            {
              role: "system",
              content: "You are a financial advisor. Analyze user data and provide comprehensive financial insights. Always respond with valid JSON."
            },
            {
              role: "user",
              content: `Analyze this financial data and provide a complete financial journey summary.
              Return ONLY valid JSON, no additional text.

              Budget Data: ${JSON.stringify(budgetData)}
              Selected Goals: ${JSON.stringify(savedGoalsData)}
              Financial Priorities: ${JSON.stringify(storedPriorities)}

              Required JSON format:
              {
                "progress_highlights": [
                  {"message": "highlight for goal 1"},
                  {"message": "highlight for goal 2"}
                ],
                "potential_improvements": [
                  {"message": "suggestion 1"},
                  {"message": "suggestion 2"}
                ],
                "milestones": [
                  {
                    "title": "milestone title",
                    "description": "detailed description with specific numbers",
                    "status": "completed|in-progress|not-started"
                  }
                ]
              }
              
              Rules for milestones:
              1. First milestone must be 'No Budget' (completed) with description 'Starting point of your financial journey.'
              2. Second milestone must be 'Budget Set!' (completed) with description 'You've taken the first step by setting your budget.'
              3. Generate all the selected priorities as milestones
              4. Base milestone status on current progress, if the change of value is >0 then the milestone is completed, if the change of value is <0 then the milestone is not started, if the change of value is 0 then the milestone is in progress
              5. Include specific numbers from their budget in descriptions and the description should state the action taken this month
              6. Make descriptions actionable and encouraging
              7. The final milestone should be 'Financial Freedom!' (not started) with description 'Your journey to financial independence is complete.'`
              
            }
          ],
          temperature: 0.7,
          max_tokens: 1000,
          response_format: { type: "json_object" } // Request JSON response
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
          }
        }
      );

      let aiResponse;
      try {
        const content = response.data.choices[0].message.content;
        // Remove any non-JSON text that might be present
        const jsonString = content.trim().replace(/^[^{]*/, '').replace(/[^}]*$/, '');
        aiResponse = JSON.parse(jsonString);
        
        // Validate the response structure
        if (!aiResponse.progress_highlights || !aiResponse.potential_improvements || !aiResponse.milestones) {
          throw new Error('Invalid response structure');
        }
      } catch (parseError) {
        console.error('Error parsing OpenAI response:', parseError);
        console.log('Raw response:', response.data.choices[0].message.content);
        throw new Error('Failed to parse OpenAI response');
      }

      // Set summary and milestones if we have valid data
      setSummary({
        progress: aiResponse.progress_highlights || [],
        improvements: aiResponse.potential_improvements || []
      });

      // Sort milestones by status priority
      const sortedMilestones = [...(aiResponse.milestones || [])].sort((a, b) => {
        const statusPriority = {
          'completed': 0,
          'in-progress': 1,
          'not-started': 2
        };
        return statusPriority[a.status] - statusPriority[b.status];
      });

      setMilestones(sortedMilestones);
      setIsLoading(false);

    } catch (error) {
      console.error('Error in fetchOpenAISummaryAndMilestones:', error);
      if (error.response) {
        console.error('OpenAI Error Response:', error.response.data);
        console.error('OpenAI Error Status:', error.response.status);
      }
      
      setIsLoading(false);

      // Only set error messages if we don't have valid summary data
      if (!summary.progress?.length && !summary.improvements?.length) {
        setSummary({
          progress: [{ message: "Unable to generate progress summary." }],
          improvements: [{ message: "Unable to generate improvements." }]
        });
      }
      
      // Only set default milestones if we don't have any
      if (!milestones || milestones.length === 0) {
        setMilestones([
          {
            title: 'No Budget',
            description: 'Starting point of your financial journey.',
            status: 'completed'
          },
          {
            title: 'Budget Set!',
            description: "You've taken the first step by setting your budget.",
            status: 'completed'
          }
        ]);
      }
    }
  };

  // Update useEffect to use the combined function
  useEffect(() => {
    const initializeData = async () => {
      if (
        budgetSummary && 
        Object.keys(budgetSummary).length > 0 &&
        savedGoalsData.length > 0 && 
        storedPriorities.length > 0
      ) {
        await fetchOpenAISummaryAndMilestones();
      }
    };
    
    initializeData();
  }, []); // Empty dependency array to run only once on mount

  const calculateProgress = () => {
    const completed = milestones.filter(m => m.status === 'completed').length;
    const inProgress = milestones.filter(m => m.status === 'in-progress').length;
    return ((completed + (inProgress * 0.5)) / milestones.length) * 100;
  };

  const getMilestoneIcon = (status) => {
    switch(status) {
      case 'completed':
        return '✓';
      case 'in-progress':
        return '⚡';
      default:
        return '○';
    }
  };

  const getMotivationalMessage = () => {
    const completed = milestones.filter(m => m.status === 'completed').length;
    const inProgress = milestones.filter(m => m.status === 'in-progress').length;
    
    if (completed === 0 && inProgress === 0) {
      return "Ready to start your journey to financial freedom? Let's take the first step together!";
    } else if (completed > 0) {
      return `Amazing! You've completed ${completed} milestone${completed > 1 ? 's' : ''}. Keep going to achieve financial freedom!`;
    } else {
      return "You're making great progress on your financial journey. Keep up the momentum!";
    }
  };

  const getChangeClass = (category, change) => {
    if (change.amount === 0) return '';
    
    // For Expenses category, reverse the color logic
    if (category === 'Expenses') {
      return change.amount > 0 ? 'conclusion-increase' : 'conclusion-decrease';
    }
    
    // For all other categories, use standard logic
    return change.amount > 0 ? 'conclusion-decrease' : 'conclusion-increase';
  };

  return (
    <div className="conclusion-financial-roadmap">
      <div className="conclusion-roadmap-header">
        <h1 className="conclusion-roadmap-title">Your Financial Roadmap</h1>
        <p className="conclusion-roadmap-subtitle">
          Congratulations on setting your budget for the month of {targetMonth}!
        </p>
      </div>

      <div className="conclusion-progress-timeline-container">
        <div className="conclusion-timeline-header">
          <h2>Your Journey to Financial Independence</h2>
          <p className="conclusion-timeline-subtext">Track your progress as you move closer to financial freedom.</p>
        </div>
        
        <div className="conclusion-timeline-progress">
          <div className="conclusion-progress-bar">
            <div className="conclusion-progress-fill" style={{ width: `${calculateProgress()}%` }}></div>
            {milestones.map((milestone, index) => (
              <div 
                key={index}
                className={`conclusion-milestone ${milestone.status}`}
                style={{ left: `${(index / (milestones.length - 1)) * 100}%` }}
              >
                <div className="conclusion-milestone-icon">
                  {getMilestoneIcon(milestone.status)}
                </div>
                <div className="conclusion-milestone-tooltip">
                  <h3>{milestone.title}</h3>
                  <p>{milestone.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="conclusion-motivation-text">
          <p>{getMotivationalMessage()}</p>
        </div>
      </div>

      <div className="conclusion-budget-table-container">
        <h2>Here is your updated budget</h2>
        <table className="conclusion-budget-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Updated Budget</th>
              <th>Changes</th>
            </tr>
          </thead>
          <tbody>
            {uniqueCategories.map(category => {
              const newAmount = getAmount(category, 'budget');
              const change = calculateChange(category, newAmount);
              
              // Function to format the change message
              const getChangeMessage = (category, change) => {
                if (change.amount === 0) return 'Maintained';
                
                const action = change.amount > 0 ? 'Increased by' : 'Reduced by';
                return `${action} ${formatCurrency(Math.abs(change.amount))}`;
              };

              return (
                <tr key={category}>
                  <td>{category}</td>
                  <td>{formatCurrency(newAmount)}</td>
                  <td className={getChangeClass(category, change)}>
                    {getChangeMessage(category, change)}
                  </td>
                </tr>
              );
            })}

            {/* Net Income Row */}
            <tr className={budgetSummary.netIncome < 0 ? 'conclusion-negative-income' : 'conclusion-positive-income'}>
              <td><strong>Net Income</strong></td>
              <td><strong>{formatCurrency(budgetSummary.netIncome)}</strong></td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <section className="conclusion-progress-summary">
        <h2>Your Progress Highlights</h2>
        
        {isLoading ? (
          <div className="conclusion-loading-summary">
            <div className="conclusion-loading-spinner"></div>
            <p>Analyzing your financial progress...</p>
          </div>
        ) : (
          <>
            <ul className="conclusion-actions-list">
              {summary.progress.map((item, index) => (
                <li key={index} className="conclusion-progress-item">
                  {item.message}
                </li>
              ))}
            </ul>

            {summary.improvements.length > 0 && (
              <>
                <h2>Future Opportunities</h2>
                <ul className="conclusion-actions-list">
                  {summary.improvements.map((item, index) => (
                    <li key={index} className="conclusion-improvement-item">
                      {item.message}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default Conclusions;
