import React, { useState, useEffect, useRef } from 'react';
import '../css/CommonLayout.css';
import '../css/RepayDebts.css';
import axios from 'axios';

const loanTypes = ["Car Loan", "Personal Loan", "Credit Card", "Home Loan", "Gold Loan", "Education Loan", "Custom"];


const generateRepaymentStrategy = async (relevantData, prompt) => {
  try {
    console.log('Calling OpenAI API with data:', { relevantData, prompt });
    const response = await axios.post('https://api.openai.com/v1/chat/completions', {
      model: "gpt-4o",
      messages: [
        { role: "system", content: "You are a helpful financial advisor specializing in debt repayment strategies." },
        { role: "user", content: prompt }
      ],
      temperature: 0.7,
      max_tokens: 1000
    }, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json'
      }
    });

    console.log('OpenAI API response:', response.data);
    if (response.data && response.data.choices && response.data.choices[0].message.content) {
      return response.data.choices[0].message.content;
    } else {
      console.error('Unexpected response format:', response.data);
      throw new Error('Unexpected response format from OpenAI API');
    }
  } catch (error) {
    console.error('Error calling OpenAI API:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

const generateOverview = async (relevantData) => {
  const strategyName = relevantData.repaymentStrategy === 'time' 
    ? "Debt Avalanche Repayment Method" 
    : "Debt Snowball Repayment Method";

  const overviewPrompt = `Based on the loan details provided, generate a brief 2-3 sentence explanation of how the debt repayment strategy selected by the user - ${strategyName} will help the user without mentioning any loans from the list. Keep the tone positive and encouraging and give the output in bullet points for easy reading.

Loan Details:
${JSON.stringify(relevantData.loans.map(loan => ({
  loanType: loan.loanType,
  monthlyEMI: loan.monthlyEMI,
  outstandingAmount: loan.amountOutstanding,
  repaymentDate: loan.repaymentDate,
  interestRate: loan.interestRate
})), null, 2)}
Extra monthly payment: ₹${relevantData.extraPayment}`;

  return await generateRepaymentStrategy(relevantData, overviewPrompt);
};

const generateFirstTarget = async (relevantData) => {
  const strategyName = relevantData.repaymentStrategy === 'time' 
    ? "Debt Avalanche Repayment Method" 
    : "Debt Snowball Repayment Method";

  // Sort and store ordered loans based on strategy
  const orderedLoans = [...relevantData.loans].sort((a, b) => {
    if (relevantData.repaymentStrategy === 'time') {
      // Debt Avalanche: Sort by interest rate in descending order
      return parseFloat(b.interestRate) - parseFloat(a.interestRate);
    } else {
      // Debt Snowball: Sort by outstanding amount in ascending order
      return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
    }
  });

  const firstLoan = orderedLoans[0];
  const extraPaymentText = parseFloat(relevantData.extraPayment) > 0 
    ? ` and the extra payment of ₹${relevantData.extraPayment} will be applied to this loan first` 
    : '';

  // Separate the explanation and the list
  const explanation = `Based on the ${strategyName}, we will tackle the ${firstLoan.loanType} first as it has the ${
    relevantData.repaymentStrategy === 'time' 
      ? `highest interest rate of ${firstLoan.interestRate}%` 
      : `lowest outstanding amount of ₹${firstLoan.amountOutstanding}`
  }${extraPaymentText}.`;

  const loanList = orderedLoans.map((loan, index) => 
    `${index + 1}. ${loan.loanType} (${
      relevantData.repaymentStrategy === 'time'
        ? `Interest Rate: ${loan.interestRate}%`
        : `Outstanding Amount: ₹${loan.amountOutstanding}`
    })`
  ).join('\n');

  const targetText = `${explanation}\n\nLoans under this strategy will be tackled in the following order:\n${loanList}`;

  return targetText;
};

const generateFirstLoanDate = async (relevantData) => {
  // Get the first loan based on strategy
  const orderedLoans = [...relevantData.loans].sort((a, b) => {
    if (relevantData.repaymentStrategy === 'time') {
      return parseFloat(b.interestRate) - parseFloat(a.interestRate);
    } else {
      return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
    }
  });

  const firstLoan = orderedLoans[0];
  const totalMonthlyPayment = parseFloat(firstLoan.monthlyEMI) + parseFloat(relevantData.extraPayment || 0);
  const negativeOutstanding = parseFloat(firstLoan.amountOutstanding) * -1;

//   const firstLoanDatePrompt = `Objective:
// To calculate the updated repayment date for the loan being prioritized according to the repayment strategy.

// Rules:
// Use the provided extra payment amount (₹${relevantData.extraPayment}) toward the prioritized loan (${firstLoan.loanType}) based on the repayment strategy:
// If prioritizing the highest interest rate, select the loan with the highest annual interest rate.
// If prioritizing the lowest outstanding balance, select the loan with the smallest outstanding principal.
// For the selected loan, add the extra payment (₹${relevantData.extraPayment}) to the monthly EMI (₹${firstLoan.monthlyEMI}) and calculate the updated repayment date using outstanding amount of ₹${negativeOutstanding} and interest rate of ${firstLoan.interestRate}%.
// Provide only the repayment date for the selected loan, without moving to the others.

// Output Expected:
// CRITICAL: Return ONLY the final repayment date in MMM YYYY format. Do not show any calculations or explanations.`;

//   return await generateRepaymentStrategy(relevantData, firstLoanDatePrompt);
// 
};

// Update the renderBulletPoints function to handle numbered lists
const renderBulletPoints = (text) => {
  const hasNumberedList = text.match(/\d+\./);
  const hasBulletPoints = text.includes('\n-') || text.includes('\n•');
  
  if (hasNumberedList) {
    return (
      <ol style={{ paddingLeft: '20px', marginTop: '10px' }}>
        {text.split('\n').map((line, index) => {
          if (line.match(/^\d+\./)) {
            return <li key={index}>{line.replace(/^\d+\./, '').trim()}</li>;
          }
          return line.trim() ? <p key={index}>{line}</p> : null;
        })}
      </ol>
    );
  } else if (hasBulletPoints) {
    return (
      <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
        {text.split('\n').map((line, index) => {
          if (line.trim().startsWith('-') || line.trim().startsWith('•')) {
            return <li key={index}>{line.replace(/^[-•]/, '').trim()}</li>;
          }
          return line.trim() ? <p key={index}>{line}</p> : null;
        })}
      </ul>
    );
  }
  return <p>{text}</p>;
};

// 1. Basic helper functions first
const calculateRemainingMonths = (repaymentDate) => {
    const today = new Date();
    const endDate = new Date(repaymentDate);
    return Math.ceil((endDate - today) / (1000 * 60 * 60 * 24 * 30.44));
};

// 2. Loan calculation helper
const calculateLoanRepayment = (loan, extraAmount, currentMonth) => {
    const updatedEMI = loan.emi + extraAmount;
    const monthsToClose = Math.ceil(
        Math.log(updatedEMI / (updatedEMI - loan.rate * loan.principal)) /
        Math.log(1 + loan.rate)
    );

    return {
        monthsToClose,
        updatedEMI,
        closingMonth: currentMonth + monthsToClose
    };
};

// 3. Data preparation functions
const formatLoansForCalculation = (loans, orderedLoans) => {
    return orderedLoans.map((orderedLoan) => {
        const loan = loans.find((l) => l.loanType === orderedLoan.loanType);
        return {
            name: loan.loanType,
            principal: parseFloat(loan.amountOutstanding),
            rate: parseFloat(loan.interestRate) / 12 / 100, // Convert annual to monthly rate
            emi: parseFloat(loan.monthlyEMI),
            tenure: calculateRemainingMonths(loan.repaymentDate),
            priority: orderedLoans.findIndex((l) => l.loanType === loan.loanType) + 1,
        };
    });
};

// 4. Main calculation function
const calculateDynamicLoanRepayment = (loans, extraEMI) => {
    const MAX_ITERATIONS = 1000;
    let iterations = 0;
    let currentMonth = 0;
    let results = [];
    let totalEMI = extraEMI;
    let remainingLoans = [...loans];

    while (remainingLoans.length > 0 && iterations < MAX_ITERATIONS) {
        iterations++;

        const currentLoan = remainingLoans[0];
        const updatedEMI = currentLoan.emi + totalEMI;

        // Calculate months to close
        const monthsToClose = Math.ceil(
            Math.log(updatedEMI / (updatedEMI - currentLoan.rate * currentLoan.principal)) /
            Math.log(1 + currentLoan.rate)
        );

        if (isNaN(monthsToClose) || monthsToClose <= 0) {
            console.error("Invalid calculation for loan:", currentLoan);
            break;
        }

        // Update loan principal and track closure
        const originalPrincipal = currentLoan.principal;
        let interestPaid = 0;

        for (let i = 0; i < monthsToClose; i++) {
            const interest = currentLoan.principal * currentLoan.rate;
            interestPaid += interest;
            const principalPaid = updatedEMI - interest;
            currentLoan.principal -= principalPaid;

            if (currentLoan.principal <= 0) {
                currentLoan.principal = 0;
                break;
            }
        }

        // Calculate closure date
        const closureDate = new Date();
        closureDate.setMonth(closureDate.getMonth() + monthsToClose);

        results.push({
            loanName: currentLoan.name,
            closeMonth: currentMonth + monthsToClose,
            totalEMIUsed: updatedEMI,
            originalEMI: currentLoan.emi,
            extraAmount: totalEMI,
            interestRate: currentLoan.rate * 12 * 100, // Convert back to annual rate
            interestSaved: (originalPrincipal * currentLoan.rate * currentLoan.tenure) - interestPaid,
            monthsSaved: currentLoan.tenure - monthsToClose,
            closureDate: closureDate.toLocaleDateString('en-IN', {
                year: 'numeric',
                month: 'short'
            })
        });

        // Update current month and totalEMI
        currentMonth += monthsToClose;
        totalEMI += currentLoan.emi;

        // Remove closed loan
        remainingLoans.shift();

        // Check for lower-priority loans that might close earlier
        for (let i = 0; i < remainingLoans.length; i++) {
            if (remainingLoans[i].principal <= 0) {
                totalEMI += remainingLoans[i].emi;
                remainingLoans.splice(i, 1);
                i--; // Adjust index after removal
            }
        }
    }

    if (iterations >= MAX_ITERATIONS) {
        console.error("Max iterations reached, possible infinite loop.");
        throw new Error("Calculation timeout. Check loan data for inconsistencies.");
    }

    return results;
};

// 5. Schedule calculation wrapper
const calculateRepaymentSchedule = (relevantData) => {
    // Sort loans based on strategy
    const orderedLoans = [...relevantData.loans].sort((a, b) => {
        if (relevantData.repaymentStrategy === "time") {
            // Debt Avalanche: Sort by interest rate (descending)
            return parseFloat(b.interestRate) - parseFloat(a.interestRate);
        } else {
            // Debt Snowball: Sort by outstanding amount (ascending)
            return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
        }
    });

    // Format loans for calculation
    const formattedLoans = formatLoansForCalculation(relevantData.loans, orderedLoans);

    // Calculate repayment details
    const repaymentDetails = calculateDynamicLoanRepayment(
        formattedLoans,
        parseFloat(relevantData.extraPayment)
    );

    // Format dates and additional information for display
    return repaymentDetails.map((result) => {
        const closureDate = new Date();
        closureDate.setMonth(closureDate.getMonth() + result.closeMonth);

        const originalMonths = calculateRemainingMonths(
            relevantData.loans.find((l) => l.loanType === result.loanName).repaymentDate
        );

        return {
            ...result,
            closureDate: closureDate.toLocaleDateString("en-IN", {
                year: "numeric",
                month: "long",
            }),
            monthsSaved: originalMonths - result.closeMonth,
        };
    });
};

/* Example usage:
const relevantData = {
    loans: [
        {
            loanType: "Personal Loan",
            monthlyEMI: 33758,
            amountOutstanding: 898779,
            repaymentDate: "2027-06-30",
            interestRate: 11.25
        },
        {
            loanType: "Gold Loan",
            monthlyEMI: 14918,
            amountOutstanding: 311360.47,
            repaymentDate: "2026-10-31",
            interestRate: 9.90
        }
    ],
    extraPayment: 20
};

const repaymentSchedule = calculateRepaymentSchedule(relevantData);
console.log(repaymentSchedule);
*/

const RepayDebts = ({ onBudgetDataChange, onSave }) => {
  const [loans, setLoans] = useState(() => {
    // Try to load initial loans data from localStorage
    const savedLoans = localStorage.getItem('loanDetails');
    return savedLoans ? JSON.parse(savedLoans) : [{ loanType: '', amount: '', interestRate: '', tenure: '', emi: '' }];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [extraPayment, setExtraPayment] = useState('');
  const [repaymentStrategy, setRepaymentStrategy] = useState('');
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [showRepaymentStrategy, setShowRepaymentStrategy] = useState(false);
  const analysisRef = useRef(null);
  const repaymentStrategyRef = useRef(null);
  const pageContainerRef = useRef(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [totalEMI, setTotalEMI] = useState(0);
  const [overviewStrategy, setOverviewStrategy] = useState(null);
  const [firstTargetStrategy, setFirstTargetStrategy] = useState(null);
  const [firstLoanDate, setFirstLoanDate] = useState('');
  const [strategyGenerated, setStrategyGenerated] = useState(false);
  const [calculationResults, setCalculationResults] = useState([]);
  const [error, setError] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculationError, setCalculationError] = useState(null);
  
  useEffect(() => {
    // Load saved data when component mounts
    const savedLoans = JSON.parse(localStorage.getItem('loans') || '[]');
    const savedExtraPayment = localStorage.getItem('extraPayment') || '';
    const savedAnalysis = JSON.parse(localStorage.getItem('loanAnalysis') || 'null');
    const savedSelectedStrategy = localStorage.getItem('selectedStrategy') || '';
    const savedRepaymentStrategy = localStorage.getItem('repaymentStrategy') || '';
    if (savedLoans.length > 0) setLoans(savedLoans);
    if (savedExtraPayment) setExtraPayment(savedExtraPayment);
    if (savedAnalysis) setAnalysis(savedAnalysis);
    if (savedSelectedStrategy) setSelectedStrategy(savedSelectedStrategy);
    if (savedRepaymentStrategy) setRepaymentStrategy(savedRepaymentStrategy);
    setHasUnsavedChanges(false);
  }, []);
  
  useEffect(() => {
    setHasUnsavedChanges(true);
  }, [loans, extraPayment, analysis, selectedStrategy, repaymentStrategy]);

  // Add this useEffect to automatically save data when loans change
  useEffect(() => {
    localStorage.setItem('loans', JSON.stringify(loans));
    localStorage.setItem('extraPayment', extraPayment);
    localStorage.setItem('loanAnalysis', JSON.stringify(analysis));
    localStorage.setItem('selectedStrategy', selectedStrategy);
    localStorage.setItem('repaymentStrategy', repaymentStrategy);
  }, [loans, extraPayment, analysis, selectedStrategy, repaymentStrategy]);

  const scrollToRef = (ref) => {
    if (ref && ref.current && pageContainerRef.current) {
      const headerHeight = 60; // Adjust this value based on your header height
      const pageContainerTop = pageContainerRef.current.offsetTop;
      const elementTop = ref.current.offsetTop;
      const scrollPosition = elementTop - pageContainerTop - headerHeight;
      window.scrollTo({top: scrollPosition, behavior: 'smooth'});
    }
  };

  const scrollToAnalysisSummary = () => {
    if (analysisRef.current) {
      analysisRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // Adjust for header height
      window.scrollBy(0, -60); // Adjust -60 based on your header height
    }
  };

  const handleAddLoan = () => {
    const updatedLoans = [...loans, { loanType: '', amount: '', interestRate: '', tenure: '', emi: '' }];
    setLoans(updatedLoans);
    setHasUnsavedChanges(true);
  };

  const handleLoanChange = (index, field, value) => {
    const updatedLoans = loans.map((loan, i) => {
        if (i === index) {
            // For tenure, ensure we're handling the input value correctly
            if (field === 'tenure') {
                // Allow empty string or positive numbers
                const newValue = value === '' ? '' : Math.max(0, parseInt(value) || 0);
                return {
                    ...loan,
                    tenure: newValue,
                    totalTenure: newValue // Update totalTenure as well if you're using it
                };
            }
            return { ...loan, [field]: value };
        }
        return loan;
    });
    
    setLoans(updatedLoans);
    localStorage.setItem('loanDetails', JSON.stringify(updatedLoans));
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    setIsLoading(true);
    try {
        // Simulate API call with setTimeout
        setTimeout(() => {
            const loanSummaries = loans.map(loan => {
                const originalLoanAmount = calculateOriginalLoanAmount(loan);
                const totalInterest = calculateTotalInterest(loan);
                const repaymentDate = calculateRepaymentDate(loan);
                return {  
                    loanType: loan.loanType,
                    originalLoanAmount,
                    amountOutstanding: parseFloat(loan.amount) || 0,
                    tenurePending: parseInt(loan.tenure) || 0,
                    monthlyEMI: parseFloat(loan.emi) || 0,
                    totalInterest,
                    repaymentDate,
                    interestRate: parseFloat(loan.interestRate) || 0
                };
            });

            setAnalysis({ loanSummaries });
            
            // Save analysis to localStorage
            localStorage.setItem('loanAnalysis', JSON.stringify({ loanSummaries }));
            
            setIsLoading(false);
            scrollToRef(analysisRef);
        }, 1000); // Simulate 1 second delay

    } catch (error) {
        console.error('Error analyzing loans:', error);
        setIsLoading(false);
    }
  };

  const calculateOriginalLoanAmount = (loan) => {
    const monthlyRate = (parseFloat(loan.interestRate) || 0) / 12 / 100;
    const tenure = parseInt(loan.tenure) || 0;
    const emi = parseFloat(loan.emi) || 0;
    
    console.log('Debug calculateOriginalLoanAmount:', {
      monthlyRate,
      tenure,
      emi,
      loan
    });
    
    return (emi * (1 - Math.pow(1 + monthlyRate, -tenure))) / monthlyRate || 0;
  };

  const calculateTotalInterest = (loan) => {
    const emi = parseFloat(loan.emi) || 0;
    const tenure = parseInt(loan.tenure) || 0;
    const originalAmount = calculateOriginalLoanAmount(loan);
    
    console.log('Debug calculateTotalInterest:', {
      emi,
      tenure,
      originalAmount,
      loan
    });
    
    return (emi * tenure) - originalAmount || 0;
  };

  const calculateRepaymentDate = (loan) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + (parseInt(loan.tenure) || 0));
    return currentDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
  };

  const handleStrategyChange = (e) => {
    const value = e.target.value;
    setSelectedStrategy(value);
    // Map the user-friendly options to the backend strategy names
    const backendStrategy = value === 'time' ? 'avalanche' : 'snowball';
    console.log('Backend strategy:', backendStrategy);
      setHasUnsavedChanges(true);
    // You can use the backendStrategy value when sending data to the backend
  };

  const handleTakeAction = () => {
    setShowRepaymentStrategy(true);
    setTimeout(() => {
      scrollToRef(repaymentStrategyRef);
    }, 100);
  };

  const handleExtraPaymentChange = (e) => {
    setExtraPayment(e.target.value);
    setHasUnsavedChanges(true);
  };

  const handleRepaymentStrategySubmit = async () => {
    setIsCalculating(true);
    setCalculationError(null);

    try {
        if (!extraPayment || !analysis?.loanSummaries?.length) {
            throw new Error("Please enter all required details");
        }

        // Prepare data for overview and strategy
        const relevantData = {
            loans: analysis.loanSummaries.map(loan => ({
                loanType: loan.loanType,
                amountOutstanding: loan.amountOutstanding,
                interestRate: loan.interestRate,
                monthlyEMI: loan.monthlyEMI,
                repaymentDate: loan.repaymentDate
            })),
            extraPayment: parseFloat(extraPayment),
            repaymentStrategy: selectedStrategy
        };

        // Generate overview
        const overview = await generateOverview(relevantData);
        
        // Generate first target explanation
        const firstTarget = await generateFirstTarget(relevantData);

        // Format loans for repayment calculation
        const formattedLoans = analysis.loanSummaries.map(loan => ({
            name: loan.loanType,
            principal: parseFloat(loan.amountOutstanding),
            rate: parseFloat(loan.interestRate) / 12 / 100,
            emi: parseFloat(loan.monthlyEMI),
            tenure: parseInt(loan.tenurePending)
        }));

        // Calculate repayment schedule
        const results = calculateDynamicLoanRepayment(formattedLoans, parseFloat(extraPayment));
        
        // Set all results
        setCalculationResults(results);
        setOverviewStrategy(overview);
        setFirstTargetStrategy(firstTarget);
        setStrategyGenerated(true);
        setHasUnsavedChanges(true);

    } catch (error) {
        console.error("Calculation error:", error);
        setCalculationError(error.message);
    } finally {
        setIsCalculating(false);
    }
  };

  const renderRepaymentStrategy = () => {
    return (
        <div className="repay-debts-strategy-display">
            {isCalculating && <div>Calculating repayment strategy...</div>}
            
            {calculationError && (
                <div className="repay-debts-error-message">{calculationError}</div>
            )}

            {overviewStrategy && (
                <div className="repay-debts-strategy-explanation">
                    <h3>Strategy Overview</h3>
                    <div>{overviewStrategy}</div>
                </div>
            )}

            {firstTargetStrategy && (
                <div className="repay-debts-strategy-explanation">
                    <h3>First Target</h3>
                    <div>{firstTargetStrategy}</div>
                </div>
            )}

            {calculationResults && calculationResults.length > 0 && (
                <div className="repay-debts-repayment-results">
                    <h3>Repayment Schedule</h3>
                    <table className="repay-debts-repayment-table">
                        <thead>
                            <tr>
                                <th>Loan Type</th>
                                <th>Original EMI</th>
                                <th>New EMI</th>
                                <th>Extra Amount</th>
                                <th>Interest Rate</th>
                                <th>Interest Saved</th>
                                <th>Months Saved</th>
                                <th>New Closure Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calculationResults.map((result, index) => (
                                <tr key={index}>
                                    <td>{result.loanName}</td>
                                    <td>₹{result.originalEMI.toLocaleString()}</td>
                                    <td>₹{result.totalEMIUsed.toLocaleString()}</td>
                                    <td>₹{result.extraAmount.toLocaleString()}</td>
                                    <td>{result.interestRate.toFixed(2)}%</td>
                                    <td>₹{Math.round(result.interestSaved).toLocaleString()}</td>
                                    <td>{result.monthsSaved}</td>
                                    <td>{result.closureDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
  };

  const handleSave = () => {
    // Calculate total monthly EMI
    const totalMonthlyEMI = loans.reduce((total, loan) => total + parseFloat(loan.emi || 0), 0);
    const totalDebtPayment = totalMonthlyEMI + parseFloat(extraPayment || 0);
    
    
    // Save data to localStorage
    localStorage.setItem('loans', JSON.stringify(loans));
    localStorage.setItem('extraPayment', extraPayment);
    localStorage.setItem('loanAnalysis', JSON.stringify(analysis));
    localStorage.setItem('selectedStrategy', selectedStrategy);
    localStorage.setItem('repaymentStrategy', repaymentStrategy);
    setHasUnsavedChanges(false);
    // Update budget data in Aggregator
    onBudgetDataChange('Debt Reduction', totalDebtPayment);
    onSave();
  };

  const handleDeleteLoan = (index) => {
    const updatedLoans = loans.filter((_, i) => i !== index);
    setLoans(updatedLoans);
    
    // Update total EMI after deletion
    const newTotalEMI = updatedLoans.reduce((sum, loan) => sum + parseFloat(loan.emi || 0), 0);
    setTotalEMI(newTotalEMI);
    
    // Update budgetData
    onBudgetDataChange('Debts', newTotalEMI);
  };
  
  const validateLoanData = (loans) => {
    if (!loans?.length) {
        throw new Error("No loans provided");
    }

    loans.forEach((loan, index) => {
        if (!loan.monthlyEMI || isNaN(loan.monthlyEMI)) {
            throw new Error(`Invalid EMI for loan ${index + 1}`);
        }
        if (!loan.amountOutstanding || isNaN(loan.amountOutstanding)) {
            throw new Error(`Invalid outstanding amount for loan ${index + 1}`);
        }
        if (!loan.interestRate || isNaN(loan.interestRate)) {
            throw new Error(`Invalid interest rate for loan ${index + 1}`);
        }
        if (!loan.repaymentDate) {
            throw new Error(`Invalid repayment date for loan ${index + 1}`);
        }
    });

    return true;
  };
  
  const prepareLoanData = (loans, strategy) => {
    return loans.map(loan => ({
        loanType: loan.loanType,
        monthlyEMI: parseFloat(loan.monthlyEMI),
        amountOutstanding: parseFloat(loan.amountOutstanding),
        repaymentDate: loan.repaymentDate,
        interestRate: parseFloat(loan.interestRate)
    }));
  };

  const sortLoansByStrategy = (loans, strategy) => {
    return [...loans].sort((a, b) => {
        if (strategy === "time") {
            return parseFloat(b.interestRate) - parseFloat(a.interestRate);
        } else {
            return parseFloat(a.amountOutstanding) - parseFloat(b.amountOutstanding);
        }
    });
  };

  return (
    <div className="repay-debts-container">
      <div className="repay-debts-description">
        <div className="repay-debts-description-header">
          <h2>Debt Repayment Planner</h2>
          <p className="repay-debts-description-subtitle">Optimize your debt repayment strategy</p>
        </div>
        <div className="repay-debts-description-features">
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon">📊</span>
            <span>Track multiple loans</span>
          </div>
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon">💡</span>
            <span>Smart payment strategies</span>
          </div>
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon">⚡</span>
            <span>Debt-free faster</span>
          </div>
          <div className="repay-debts-feature-item">
            <span className="repay-debts-feature-icon">📈</span>
            <span>Interest savings</span>
          </div>
        </div>
      </div>
      <h3 className="repay-debts-loan-details-heading">Loan Details</h3>
      <div className="repay-debts-loan-input-section">
        <form onSubmit={handleSubmit}>
          <div className="repay-debts-loan-table-container">
            <table className="repay-debts-loan-details-table">
              <thead>
                <tr>
                  <th>Loan Type</th>
                  <th>Amount</th>
                  <th>Interest Rate (%)</th>
                  <th>Tenure (months)</th>
                  <th>EMI</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loans.map((loan, index) => (
                  <tr key={index}>
                    <td>
                      <select
                        value={loan.loanType}
                        onChange={(e) => handleLoanChange(index, 'loanType', e.target.value)}
                      >
                        <option value="">Select loan type</option>
                        {loanTypes.map((type) => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="number"
                        value={loan.amount}
                        onChange={(e) => handleLoanChange(index, 'amount', e.target.value)}
                        placeholder="Enter amount"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={loan.interestRate}
                        onChange={(e) => handleLoanChange(index, 'interestRate', e.target.value)}
                        placeholder="Enter rate"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        value={loan.tenure}
                        onChange={(e) => handleLoanChange(index, 'tenure', e.target.value)}
                        placeholder="Enter tenure"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={loan.emi}
                        onChange={(e) => handleLoanChange(index, 'emi', e.target.value)}
                        placeholder="Enter EMI"
                      />
                    </td>
                    <td>
                      <button 
                        type="button"
                        onClick={() => handleDeleteLoan(index)}
                        className="repay-debts-delete-button"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="repay-debts-button-container">
            <button
              type="button"
              onClick={handleAddLoan}
              className="repay-debts-add-button"
            >
              Add another loan
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="repay-debts-analyze-button"
            >
              Analyze my loans
            </button>
          </div>
        </form>
      </div>
      {isLoading && <p>Analyzing your loans...</p>}
      {analysis && analysis.loanSummaries && (
        <div className="repay-debts-analysis-results">
          <div className="repay-debts-analysis-summary">
            <h3>Loan Analysis Summary</h3>
            <div className="repay-debts-loan-summary-table-container">
              <table className="repay-debts-loan-summary-table">
                <thead>
                  <tr>
                    <th>Loan Type</th>
                    <th>Total Repayment Amount</th>
                    <th>Outstanding Loan amount</th>
                    <th>Interest Rate (%)</th>
                    <th>Total Interest</th>
                    <th>Monthly EMI</th>
                    <th>Tenure Pending</th>
                    <th>Loan Repayment Date</th>
                  </tr>
                </thead>
                <tbody>
                  {analysis.loanSummaries.map((loan, index) => (
                    <tr key={index}>
                      <td>{loan.loanType}</td>
                      <td>{((loan.monthlyEMI * loan.tenurePending) || 0).toFixed(2)}</td>
                      <td>{(loan.amountOutstanding || 0).toFixed(2)}</td>
                      <td>{(loan.interestRate || 0).toFixed(2)}</td>
                      <td>{(((loan.monthlyEMI * loan.tenurePending) - loan.amountOutstanding) || 0).toFixed(2)}</td>
                      <td>{(loan.monthlyEMI || 0).toFixed(2)}</td>
                      <td>{loan.tenurePending} months</td>
                      <td>{loan.repaymentDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button onClick={handleTakeAction} className="repay-debts-take-action-btn">Take Action</button>
          </div>
          {showRepaymentStrategy && (
            <div className="repay-debts-repayment-strategy">
              <h3>Repayment Strategy</h3>
              <table className="repay-debts-strategy-options-table">
                <thead>
                  <tr>
                    <th colSpan="2">Which repayment strategy would you like to use?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Reduce debt faster (optimize for time)</td>
                    <td>
                      <input 
                        type="radio" 
                        name="strategy" 
                        value="time" 
                        checked={selectedStrategy === 'time'}
                        onChange={handleStrategyChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Reduce the number of loans faster (optimize for count)</td>
                    <td>
                      <input 
                        type="radio" 
                        name="strategy" 
                        value="count" 
                        checked={selectedStrategy === 'count'}
                        onChange={handleStrategyChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {selectedStrategy && (
            <div className="repay-debts-extra-payment-box">
              <h3>Extra Payment</h3>
              <p>To reduce your debt faster, how much extra would you like to pay each month?</p>
              <input
                type="number"
                value={extraPayment}
                onChange={handleExtraPaymentChange}
                placeholder="Enter extra payment amount"
              />
              <button onClick={handleRepaymentStrategySubmit} className="repay-debts-generate-strategy-btn">
                Generate repayment strategy
              </button>
            </div>
          )}
          {renderRepaymentStrategy()}
        </div>
      )}
      {strategyGenerated && (
        <div className="repay-debts-save-button-container">
          <button 
            onClick={handleSave} 
            disabled={!hasUnsavedChanges}
            className="repay-debts-save-button"
          >
            {hasUnsavedChanges ? 'Save Details' : 'Details Saved'}
          </button>
        </div>
      )}
    </div>
  );
};

export default RepayDebts;