import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css'; // Ensure you have the appropriate CSS file

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="app-name">
          <h1>Prospernow.ai</h1>
        </div>
        <div className="menu-container" ref={menuRef}>
          <button className="menu-button" onClick={toggleMenu}>
            <span className="menu-icon">&#9776;</span> {/* Hamburger icon */}
          </button>
          {isMenuOpen && (
            <nav className="dropdown-menu">
              <Link to="/" className="nav-link" onClick={handleOptionClick}>Home</Link>
              <Link to="/login" className="nav-link" onClick={handleOptionClick}>Login</Link>
              <Link to="/spend-analyzer" className="nav-link" onClick={handleOptionClick}>Spend Analyzer</Link>
              <Link to="/emergency-fund" className="nav-link" onClick={handleOptionClick}>Emergency Fund</Link>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
