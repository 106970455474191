import React, { useState, useEffect } from 'react';
import ShortTermSavings from './ShortTermSavings';
import RepayDebts from './RepayDebts';
import EmergencyFund from './EmergencyFund';
import LongTermSavings from './LongTermSavings';
import Insurance from './Insurance';
import Retirement from './Retirement';
import BudgetSummary from './BudgetSummary';
import '../css/Aggregator.css';
import ExpenseTracker from './ExpenseTracker';
import { useNavigate } from 'react-router-dom';

const goalComponents = {
  'Short-term Savings': ShortTermSavings,
  'Debt Reduction': RepayDebts,
  'Emergency Fund': EmergencyFund,
  'Long-Term Savings': LongTermSavings,
  'Insurance': Insurance,
  'Retirement Fund': Retirement,
  'Expense Reduction': ExpenseTracker,
};

function Aggregator() {
  const navigate = useNavigate();
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [budgetData, setBudgetData] = useState({
    Earnings: 0,
    
    Spends: 0,
    'Emergency Fund': 0,
    'Short Term Savings': 0,
    'Long Term Savings': 0,
    Debts: 0,
    Insurance: 0,
    Retirement: 0,
  });
  const [savedGoals, setSavedGoals] = useState([]);
  const [netIncome, setNetIncome] = useState(0);
  const [spendBreakdown, setSpendBreakdown] = useState(null);

  useEffect(() => {
    // Load saved data when component mounts
    const savedGoalsData = JSON.parse(localStorage.getItem('selectedGoals') || '[]');
    setSelectedGoals(savedGoalsData);
    
    // Load spend breakdown data
    const savedSpendBreakdown = JSON.parse(localStorage.getItem('spendBreakdown') || 'null');
    setSpendBreakdown(savedSpendBreakdown);
    
    // Load budget data with spend breakdown values
    const savedBudgetData = JSON.parse(localStorage.getItem('budgetData') || '{}');
    setBudgetData(prevData => ({ ...prevData, ...savedBudgetData }));
    
    const savedGoalsProgress = JSON.parse(localStorage.getItem('savedGoals') || '[]');
    setSavedGoals(savedGoalsProgress);

    // Get the income from localStorage
    const monthlyIncome = localStorage.getItem('monthlyIncome');
    if (monthlyIncome) {
      setBudgetData(prevData => ({ ...prevData, Earnings: parseFloat(monthlyIncome) }));
    }

    console.log('Loaded spend breakdown:', savedSpendBreakdown);
  }, []);

  useEffect(() => {
    const newProgress = ((currentGoalIndex + 1) / selectedGoals.length) * 100;
    setProgress(newProgress);
  }, [currentGoalIndex, selectedGoals]);

  const handleNext = () => {
    if (currentGoalIndex < selectedGoals.length - 1) {
      setCurrentGoalIndex(currentGoalIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentGoalIndex > 0) {
      setCurrentGoalIndex(currentGoalIndex - 1);
    }
  };

  const handleBudgetDataChange = (category, value) => {
    const updatedBudgetData = { ...budgetData, [category]: parseFloat(value) || 0 };
    setBudgetData(updatedBudgetData);
    localStorage.setItem('budgetData', JSON.stringify(updatedBudgetData));
  };

  const handleSaveGoal = () => {
    const updatedSavedGoals = [...savedGoals];
    if (!updatedSavedGoals.includes(selectedGoals[currentGoalIndex])) {
      updatedSavedGoals.push(selectedGoals[currentGoalIndex]);
    }
    setSavedGoals(updatedSavedGoals);
    localStorage.setItem('savedGoals', JSON.stringify(updatedSavedGoals));
  };

  const handleEditGoal = () => {
    const updatedSavedGoals = savedGoals.filter(goal => goal !== selectedGoals[currentGoalIndex]);
    setSavedGoals(updatedSavedGoals);
    localStorage.setItem('savedGoals', JSON.stringify(updatedSavedGoals));
  };

  const isCurrentGoalSaved = savedGoals.includes(selectedGoals[currentGoalIndex]);

  const CurrentGoalComponent = goalComponents[selectedGoals[currentGoalIndex]];

  const calculateTotalExpenses = () => {
    return Object.entries(budgetData)
      .filter(([key]) => key !== 'Earnings')
      .reduce((total, [_, value]) => total + value, 0);
  };

  const calculateBalance = () => {
    return budgetData.Earnings - calculateTotalExpenses();
  };

  const balance = calculateBalance();
  const isNegativeBalance = balance < 0;

  const handleShortTermSavingsUpdate = (total) => {
    setBudgetData((prevData) => ({
      ...prevData,
      'Short Term Savings': total, // Update the Short Term Savings value
    }));
  };

  const handleNetIncomeChange = (value) => {
    setNetIncome(value);
  };

  const handleFinish = () => {
    // Get the formatted budget data and spend breakdown
    const budgetSummary = JSON.parse(localStorage.getItem('budgetSummary'));
    const spendBreakdown = JSON.parse(localStorage.getItem('spendBreakdown'));
    
    if (!budgetSummary) {
      console.error('No budget summary data available');
      return;
    }

    console.log('Sending budget summary to Conclusions:', budgetSummary);
    console.log('Sending spend breakdown to Conclusions:', spendBreakdown);

    // Navigate to conclusions with both sets of data
    navigate('/conclusions', { 
      state: { 
        budgetSummary,
        spendBreakdown 
      }
    });
  };

  useEffect(() => {
    const aggregatorContainer = document.querySelector('.aggregator-container');
    const mainContent = document.querySelector('.aggregator-main-content');
    const pageContainer = document.querySelector('.aggregator-page-container');
    const contentContainer = document.querySelector('.aggregator-content-container');
    const goalContent = document.querySelector('.goal-content');

    console.log('Container Widths:');
    console.log('- aggregator-container:', aggregatorContainer?.offsetWidth);
    console.log('- main-content:', mainContent?.offsetWidth);
    console.log('- page-container:', pageContainer?.offsetWidth);
    console.log('- content-container:', contentContainer?.offsetWidth);
    console.log('- goal-content:', goalContent?.offsetWidth);
  }, [currentGoalIndex]); // Log whenever the goal changes

  return (
    <div className="aggregator-container">
      <div className="aggregator-main-content">
        <h2>Your Financial Journey</h2>
        <div className="progress-section">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
          <div className="progress-text">
            {`Goal ${currentGoalIndex + 1} of ${selectedGoals.length}: ${selectedGoals[currentGoalIndex]}`}
          </div>
        </div>
        <div className="goal-navigation">
          {selectedGoals.map((goal, index) => (
            <button
              key={index}
              className={`goal-nav-button ${index === currentGoalIndex ? 'active' : ''}`}
              onClick={() => setCurrentGoalIndex(index)}
            >
              {goal}
            </button>
          ))}
        </div>
        <div className="aggregator-page-container">
          <div className="aggregator-content-container">
            <div className="goal-content">
              {CurrentGoalComponent ? (
                <CurrentGoalComponent
                  budgetData={budgetData}
                  onBudgetDataChange={handleBudgetDataChange}
                  onSave={handleSaveGoal}
                  onEdit={handleEditGoal}
                  isSaved={isCurrentGoalSaved}
                  onShortTermSavingsUpdate={handleShortTermSavingsUpdate}
                />
              ) : (
                <p>Component not found for this goal.</p>
              )}
            </div>
          </div>
        </div>
        <div className="navigation-buttons">
          <button onClick={handlePrevious} disabled={currentGoalIndex === 0}>Previous</button>
          <button onClick={handleNext} disabled={currentGoalIndex === selectedGoals.length - 1 || !savedGoals.includes(selectedGoals[currentGoalIndex])}>Next</button>
        </div>
      </div>
      <div className="budget-summary-container">
        <BudgetSummary 
          budgetData={budgetData} 
          onNetIncomeChange={handleNetIncomeChange}
          spendBreakdown={spendBreakdown}
        />
        <div className={`sticky-note ${netIncome === 0 ? 'balanced' : 'unbalanced'}`}>
          <p className="sticky-note-text">
            {netIncome === 0 
              ? "Perfect! Your budget is balanced." 
              : netIncome > 0 
                ? `You have ₹${netIncome.toFixed(2)} unallocated. Consider adding it to your goals.`
                : `Warning: You're over budget by ₹${Math.abs(netIncome).toFixed(2)}, Make Adjustments to your goals`
            }
          </p>
        </div>
        <button 
          className="finish-button"
          onClick={handleFinish}
        >
          Finish
        </button>
      </div>
    </div>
  );
}

export default Aggregator;
